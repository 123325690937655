import type { AttachmentForm } from 'client-lib/src/lib/utils/hooks/helpers/types';
import uniqid from 'uniqid';

const handleSetAttachment = (
  originalFile: File,
  originalFilename: string = originalFile.name,
  handleAttachmentChange: (attachment: AttachmentForm) => void = () => {}
) => {
  const reader = new window.FileReader();

  reader.onload = () => {
    const fileAttachment: AttachmentForm = {
      data: reader.result,
      originalFilename,
      type: originalFile.type,
      id: uniqid(),
      size: originalFile.size,
    };

    handleAttachmentChange(fileAttachment);
  };

  reader.readAsDataURL(originalFile);
};

export default handleSetAttachment;
