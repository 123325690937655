import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/app';
import INPUT_THEMES from '../../styles/themes/library/textInput';

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const StyledPointerWrapper = styled.div`
  cursor: pointer;
`;

const StyledToolTip = styled.div`
  position: fixed;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  color: ${TEXT};
  border-radius: 4px;
  opacity: 1 !important;
  padding: 10px;
  border: 1px solid ${INPUT_THEMES.BORDER} !important;
  box-shadow: ${THEMES.BOX_SHADOW};
  margin-right: 4px;
  margin-top: 8px;
  z-index: 1000;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 18px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: ${INPUT_THEMES.BORDER};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 19px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: ${THEMES.BACKGROUND_PRIMARY};
  }
`;

const InfiniteTooltip = ({
  children,
  elementsContent,
  disabled,
  ...props
}: {
  children: React.ReactNode;
  id: string;
  elementsContent: React.ReactElement;
  disabled?: boolean;
}) => {
  const [show, setShow] = React.useState(false);

  const onMouseEnter = () => {
    setShow(true);
  };

  const onsMouseLeave = () => {
    setShow(false);
  };

  return (
    <StyledPointerWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onsMouseLeave}
    >
      {children}
      {!disabled && (
        <StyledToolTip
          {...props}
          style={{
            display: show ? 'block' : 'none',
          }}
        >
          {elementsContent}
        </StyledToolTip>
      )}
    </StyledPointerWrapper>
  );
};

export default InfiniteTooltip;
