import {
  ApolloClient,
  useMutation,
  type OperationVariables,
} from "@apollo/client";
import moment from "moment";

import BULK_CREATE_TASK_MUTATION from "../../../graphql/mutation/BulkCreateTaskMutation.graphql";
import CREATE_TASK_MUTATION from "../../../graphql/mutation/CreateTaskMutation.graphql";
import UPDATE_TASK_MUTATION from "../../../graphql/mutation/UpdateTaskMutation.graphql";
import TASKS_QUERY from "../../../graphql/query/TasksQuery.graphql";
import type { I18n } from "../../../utils/helpers/types";
import useForm from "../../../utils/hooks/useForm";
import type {
  FormValueAttachmentToDb,
  InitialTaskData,
  Task,
  TaskForm,
  TaskFormValues,
} from "./types";
import type {
  GenerateUploadUrlResponse,
  InputFields,
} from "../../../utils/hooks/helpers/types";
import useUploadAttachment from "../attachment/useUploadAttachment";
import { ASSIGNEE_DEFAULT_VALUE } from "./utils/constants";

interface BulkCreateTaskSuccess {
  __typename: string;
  errors: Array<unknown>;
  jobId: number;
  recipientCount: number;
}

interface UseUpsertTaskProps {
  client: ApolloClient<object>;
  taskData?: Task | null;
  allGroups: Array<{ id: string; name: string }>;
  translatedRequiredMessage: string;
  i18n: I18n;
  handleOnSuccess: (data?: BulkCreateTaskSuccess) => void;
  handleOnError: (error: unknown) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  initialFields?: TaskForm;
  initialTaskData?: InitialTaskData;
}

const tasksWithLabels = (
  groups: Array<{ id: string; name: string }>,
  task: Task | null
): TaskFormValues | null => {
  if (!groups?.length || !task?.groupId) return null;

  const group = groups.find(({ id }) => id === task.groupId);

  if (!group?.id || !group?.name) return null;

  const groupLabel = {
    value: group.id,
    label: group.name,
  };

  const assigneeLabel = task?.assignee?.id
    ? {
        value: task?.assignee?.id,
        label: `${task?.assignee?.firstName ?? ""} ${task?.assignee?.lastName ?? ""}`,
      }
    : ASSIGNEE_DEFAULT_VALUE;

  return {
    ...task,
    description: task.description || "",
    assignee: assigneeLabel,
    group: groupLabel,
    attachment: task?.attachment || null,
    template: task?.template
      ? {
          id: task.template.id,
          name: task.template.name || "",
        }
      : null,
  };
};

const useUpsertTask = ({
  client,
  taskData = null,
  allGroups = [],
  translatedRequiredMessage,
  i18n,
  handleOnSuccess,
  handleOnError,
  setIsSubmitting,
  initialFields,
  initialTaskData,
}: UseUpsertTaskProps) => {
  const options: OperationVariables = { ...client };

  const [createTaskMutation] = useMutation(CREATE_TASK_MUTATION, options);
  const [updateTaskMutation] = useMutation(UPDATE_TASK_MUTATION, options);
  const [bulkCreateTask] = useMutation(BULK_CREATE_TASK_MUTATION, options);

  const uploadAttachment = useUploadAttachment({ client });

  const requiredValidation = {
    type: "required",
    errorMessage: translatedRequiredMessage,
  };

  const taskNameCharMax = 75;

  const taskNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: taskNameCharMax,
    errorMessage: i18n.t("tasks-error-nameTooLong", {
      charMax: taskNameCharMax,
      defaultValue: `Task name must be ${taskNameCharMax} characters or less.`,
    }),
    optional: true,
  };

  const taskWithLabels = tasksWithLabels(allGroups, taskData);
  const isCreating = !taskWithLabels?.id;
  const typedInitialFormFields = initialFields as unknown as InputFields;
  const initialFormFields =
    initialTaskData?.bulkRecipientIds?.length &&
    typedInitialFormFields?.recipient
      ? {
          ...typedInitialFormFields,
          recipient: {
            ...typedInitialFormFields.recipient,
            value: initialTaskData.bulkRecipientIds,
          },
        }
      : typedInitialFormFields;

  const initialFormValues = initialFormFields || {
    description: {
      value: taskWithLabels?.description || "",
    },
    dueDate: {
      value: taskWithLabels?.dueDate
        ? moment(taskWithLabels.dueDate).format("YYYY-MM-DD")
        : "",
      validations: [requiredValidation],
    },
    group: {
      value: taskWithLabels?.group || null,
      validations: [requiredValidation],
    },
    name: {
      value: taskWithLabels?.name || "",
      validations: [requiredValidation, taskNameMaxLengthValidation],
    },
    recipient: {
      value: taskWithLabels?.recipient || null,
      validations: [requiredValidation],
    },
    assignee: {
      value: taskWithLabels?.assignee || ASSIGNEE_DEFAULT_VALUE,
    },
    template: {
      value: taskWithLabels?.template?.id
        ? {
            value: taskWithLabels.template.id,
            label: taskWithLabels.template.name || "",
          }
        : null,
    },
    channelType: {
      value: taskWithLabels?.channelType || "SMS",
    },
    attachment: {
      value: taskWithLabels?.attachment || null,
    },
    ...(isCreating
      ? {}
      : {
          id: {
            value: taskWithLabels?.id,
          },
        }),
  };

  const { fields, updateField, onSubmit, isVisitedForm } = useForm(
    initialFormValues,
    async (formFields) => {
      const taskFormFields = formFields as unknown as TaskForm;
      const assigneeId =
        taskFormFields.assignee?.value?.value === ""
          ? null
          : taskFormFields.assignee?.value?.value;

      const currentAttachment = taskWithLabels?.attachment;
      const formAttachment = taskFormFields?.attachment?.value;
      const attachmentIdsMatch = currentAttachment?.id === formAttachment?.id;

      let attachment: FormValueAttachmentToDb | Record<never, never> | null =
        null;

      if (formAttachment && !attachmentIdsMatch) {
        const uploadedAttachment = (await uploadAttachment(
          formAttachment
        ).catch((err) => {
          handleOnError(err);
        })) as GenerateUploadUrlResponse;

        if (uploadedAttachment?.url) {
          attachment = {
            url: uploadedAttachment.url,
            method: "EMBED",
          };
        } else {
          handleOnError(uploadedAttachment);
        }
      }

      const baseVariables = {
        description: taskFormFields?.description?.value,
        dueDate: moment(taskFormFields?.dueDate?.value)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
        groupId: taskFormFields?.group?.value?.value,
        name: taskFormFields?.name?.value,
        templateId: taskFormFields.template?.value?.value || null,
        channelType: taskFormFields.channelType?.value || "",
        assigneeId,
        ...(Array.isArray(taskFormFields.recipient?.value)
          ? { recipientIds: taskFormFields.recipient.value }
          : { recipientId: taskFormFields.recipient?.value?.id || "" }),
        ...(formAttachment && attachmentIdsMatch ? {} : { attachment }),
      };

      const variables = isCreating
        ? baseVariables
        : { ...baseVariables, id: formFields?.id?.value };

      setIsSubmitting(true);

      if (Array.isArray(taskFormFields.recipient?.value)) {
        try {
          const { data } = await bulkCreateTask({
            variables,
            refetchQueries: [TASKS_QUERY],
            onQueryUpdated: (observableQuery) => {
              return observableQuery.refetch();
            },
          });

          if (data?.bulkCreateTask?.errors?.length) {
            handleOnError(data?.bulkCreateTask?.errors);
          } else {
            handleOnSuccess();
          }
        } catch (error) {
          handleOnError(error);
        }

        return;
      }

      try {
        const mutation = isCreating ? createTaskMutation : updateTaskMutation;
        const { data } = await mutation({
          variables,
          refetchQueries: [TASKS_QUERY],
          onQueryUpdated: (observableQuery) => {
            return observableQuery.refetch();
          },
        });
        if (data?.createLabel?.errors) {
          handleOnError(data?.createLabel?.errors);
        } else {
          handleOnSuccess();
        }
      } catch (error) {
        handleOnError(error);
      }
    }
  );

  return {
    updateField,
    fields,
    onSubmit,
    isVisitedForm,
  };
};

export default useUpsertTask;
