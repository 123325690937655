import React from 'react';
import i18n from 'i18n-js';
import {
  TableHead,
  Checkbox,
  EmphasisText,
  Button,
  Avatar,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  ContactDataWrapper,
  getStyledNameTextEllipsis,
  AvatarWrapper,
  StyledTableRow,
  StyledCheckboxTD,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { addSelectedDataTestId, getSortOptions } from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll.js';
import THEMES from '../../../../styles/themes/app.js';

interface ListsTableProps {
  lists: Array<{ id: string; name: string }>;
  selectedAudience?: {
    tagIds: string[];
    remove?: boolean;
  };
  loading: boolean;
  loadMoreRows: () => void;
  onAdd: (args: { tagIds: string[]; remove?: boolean }) => void;
  onSort: (args: { label: string; value: string }) => void;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount?: number;
  };
  sort: {
    label: string;
    value: string;
  };
  heightDifference?: number;
  disableCheckboxes?: boolean;
  type?: string;
}

const ListsTable = ({
  lists,
  selectedAudience = {
    tagIds: [],
  },
  loading,
  loadMoreRows,
  onAdd,
  onSort,
  pageInfo,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
  type = '',
}: ListsTableProps) => {
  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, shouldCheckItem } =
    useMultiSelectTableItems({
      items: lists,
    });

  const shouldItemBeChecked = (id: string) => {
    if (type === 'TASK') {
      return selectedAudience?.tagIds?.includes(id);
    }
    return shouldCheckItem(id);
  };

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ tagIds: selectedItems });
    handleSelectNone();
  };

  const onCheckItem = (id: string) => {
    if (type === 'TASK') {
      const isSelected = selectedAudience.tagIds.includes(id);
      onAdd({
        tagIds: [id],
        remove: isSelected,
      });
    }
    handleCheckItem(id);
  };

  const handleKeyDown = (e: React.KeyboardEvent, id: string) => {
    if (e.key === 'Enter') {
      onCheckItem(id);
    }
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitSort={onSort}
        />
        {type !== 'TASK' ? (
          <Button
            dataTestId={`labels-table-${addSelectedDataTestId}`}
            onClick={() => handleAdd()}
            disabled={selectedItems.length === 0 || disableCheckboxes}
          >
            {i18n.t('broadcasts-broadcast-addSelected', {
              defaultValue: 'Add %{number} Selected',
              number: selectedItems.length,
            })}
          </Button>
        ) : null}
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <th>
              {i18n.t('modals-AddOrEditList-listName', {
                defaultValue: 'List Name',
              })}
            </th>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={lists}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults')}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: {
          list: Array<{ id: string; name: string }>;
          index: number;
        }) => {
          const { list, index } = args;
          const listItem = list[index] as { id: string; name: string };

          return (
            <StyledTableRow
              key={listItem.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disableCheckboxes) return;
                onCheckItem(listItem.id);
              }}
              onKeyDown={(e) => {
                if (disableCheckboxes) return;
                handleKeyDown(e, listItem.id);
              }}
            >
              <StyledCheckboxTD flexPercent={100}>
                <Checkbox
                  checked={shouldItemBeChecked(listItem.id)}
                  dataTestId="create-broadcastList-checkboxList"
                  onCheck={() => {
                    /* Not needed - entire table row is clickable */
                  }}
                  disabled={disableCheckboxes}
                />
                <AvatarWrapper>
                  <Avatar size="md" icon="tag" disabled={disableCheckboxes} />
                  <ContactDataWrapper>
                    <EmphasisText
                      customStyle={getStyledNameTextEllipsis}
                      disabled={disableCheckboxes}
                      title={listItem.name}
                    >
                      {listItem.name}
                    </EmphasisText>
                  </ContactDataWrapper>
                </AvatarWrapper>
              </StyledCheckboxTD>
            </StyledTableRow>
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

export default ListsTable;
