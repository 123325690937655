import { useEffect } from 'react';
import { useComponentWillMount } from 'client-lib';

type Props = {
  dispatchActiveSidebar: (state: 'hidden' | 'default') => void;
};

const useHideSidebarForRoute = ({ dispatchActiveSidebar }: Props) => {
  useComponentWillMount(() => dispatchActiveSidebar('hidden'));
  useEffect(() => () => dispatchActiveSidebar('default'), []);
};

export default useHideSidebarForRoute;
