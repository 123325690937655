import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  availableGroupsByGroupIds,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import i18n from 'i18n-js';
import AsyncSelect from '../../elements/Select/AsyncSelect/AsyncSelect.tsx';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const GroupAsyncSelect = ({
  value,
  setValue,
  error,
  omitAll,
  omitIds,
  permissionFilter = AVAILABLE_PERMISSIONS.MANAGE_TEAM,
  skipPermissionCheck = false,
  ...props
}) => {
  const [queryError, setQueryError] = useState('');

  const currentUser = useSelector((state) => state?.session?.currentUser);
  const account = useSelector((state) => state?.accountData?.account);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);

  useGetUserAccountPolicies({
    actionList: [permissionFilter],
    userId: currentUser?.userId,
  });

  // only give options based on permissions :)
  const generateOptions = () => {
    let groupsAvailable = [];

    const canManageTeam =
      skipPermissionCheck ||
      checkIfCurrentUserHasPermission(
        permissionFilter,
        currentUser.accountPolicies,
        currentUser.groupPolicies
      );

    if (canManageTeam || account?.ff_global_customer_list) {
      // if global_customer list is on then we need to allow all groups
      // otherwise we need to limit to the groups the user is in
      const userGroupIds = account?.ff_global_customer_list
        ? allGroups.map((group) => group.id)
        : currentUser?.groupIds || [];
      const generatedAvailableGroups = availableGroupsByGroupIds(
        allGroups,
        userGroupIds
      );

      const allOption = !omitAll
        ? [
            {
              label: i18n.t('settings-manageRules-allGroups', {
                defaultValue: 'All',
              }),
              value: 'all',
            },
          ]
        : [];

      const filteredAvailableGroups = omitIds.length
        ? generatedAvailableGroups.filter(
            ({ value }) => !omitIds.includes(value)
          )
        : generatedAvailableGroups;

      groupsAvailable = [...allOption, ...filteredAvailableGroups];
    }

    return groupsAvailable;
  };

  const loadOptions = async (inputVal) => {
    setQueryError('');

    const permissionGroups = generateOptions();
    return permissionGroups.filter((group) =>
      group.label.toLowerCase().includes(inputVal.toLowerCase())
    );
  };

  return (
    <AsyncSelect
      key="group-async-select"
      value={value}
      onChange={(selectedOption) => setValue(selectedOption)}
      loadOptions={loadOptions}
      error={queryError || error}
      dataTestId="group-async-select"
      {...props}
    />
  );
};

GroupAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  omitAll: PropTypes.bool,
  omitIds: PropTypes.array,
  permissionFilter: PropTypes.string,
  queryVars: PropTypes.object,
  skipPermissionCheck: PropTypes.bool,
};

GroupAsyncSelect.defaultProps = {
  value: '',
  error: '',
  omitAll: false,
  omitIds: [],
  permissionFilter: AVAILABLE_PERMISSIONS.MANAGE_TEAM,
  queryVars: undefined,
  skipPermissionCheck: false,
};

export default GroupAsyncSelect;
