import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { useHistory, Redirect } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useSignIn } from 'client-lib';
import i18n from 'i18n-js';
import * as Sentry from '@sentry/react';
import { MessageContainer, Message } from '../../components/Settings/styles';
import { Heading1, TextInput, Button, Loading } from '../../elements';
import THEMES from '../../styles/themes/app';
import { Container } from './styles.ts';
import {
  setHasQueriedWhitelabel,
  setWhitelabel,
} from '../../actions/whitelabel.ts';
import { setBackupLanguage, setSloUri } from '../../actions/general';

const InputGridWrapper = styled(Grid)`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 28px;
`;

const PasswordIcon = styled.i`
  color: ${THEMES.COLORED_BACKGROUND_SECONDARY}!important;
  :hover,
  :focus,
  :active {
    cursor: pointer;
  }
`;

const SignIn = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [signin_uri, setSignInUri] = useState('');
  const [passwordIconType, setPasswordIconType] = useState('hidden');
  const history = useHistory();

  useEffect(() => {
    verifySubdomain();
  }, []);

  const client = useApolloClient();

  const handleSignInSuccess = (session) => {
    const sessionUser = session.user;
    const absintheLink = client.connectSocket(
      sessionUser.id,
      sessionUser.accountId
    );

    absintheLink.onPresenceJoin(() => {
      window.ReactNativeWebView?.postMessage(JSON.stringify(session));
    });
  };

  const verifySubdomain = async () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    // dont double tap this api call if the page sites on sso
    if (subdomain && subdomain !== 'sso') {
      const data = await fetch(
        `${window.location.origin}/status/subdomain/${subdomain}`
      )
        .then((response) => response.json())
        .catch((e) => {
          console.warn(e.message);
          dispatch(setHasQueriedWhitelabel());
        });

      // whitelabel settings
      if (data.ff_whitelabel) {
        const whitelabelData = {
          whitelabel: data.ff_whitelabel,
          whitelabelLogo: data.ff_whitelabel_logo,
          whitelabelTabTitle: data.ff_whitelabel_tab_title,
        };
        dispatch(setWhitelabel(whitelabelData));
      } else {
        dispatch(setHasQueriedWhitelabel());
      }

      // backup lang settings, will be used when user is logged out and cannot access user preferences.
      if (data?.language) {
        dispatch(setBackupLanguage(data.language));
      }

      if (data?.ff_slo_uri) {
        dispatch(setSloUri(data.ff_slo_uri));
      }

      if (data.status === 'not_found') {
        setStatus('not_found');
      } else if (data.status === 'sso') {
        setStatus('sso');
        setSignInUri(data.signin_uri);
      } else if (data.status === 'moved_permanently' && data.group) {
        window.location.href = data.group;
      } else if (data.status === 'ok') {
        setStatus('ok');
      }
    }
  };

  const handleSSOSigninRedirect = async () => {
    window.location.href = signin_uri;
  };

  const {
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    handlePasswordToggle,
    passwordFieldType,
    signInError,
    isSubmitting,
    handleOnKeyPress,
  } = useSignIn({
    client,
    handleSignInSuccess,
    i18n,
    Sentry: status === 'sso' ? Sentry : null,
  });

  const { username, password } = fields;

  const handlePasswordIconToggle = () => {
    setPasswordIconType(passwordIconType === 'hidden' ? 'visible' : 'hidden');
    handlePasswordToggle();
  };

  const handleSubmitPress = () => {
    // mask error screen in mobile during sign in
    window.ReactNativeWebView?.postMessage('loading');
    onSubmit();
  };

  if (status === 'not_found') {
    return <Redirect to="/notfound" />;
  }

  if (status === 'sso') {
    return (
      <div>
        <Button onClick={handleSSOSigninRedirect}>
          {i18n.t('signin-SignIn-signIn')}
        </Button>
      </div>
    );
  }

  if (status === 'ok') {
    return isVisitedForm && isSubmitting ? (
      <Loading size="sm" />
    ) : (
      <Container>
        <Grid data-testid="sign-in" container alignItems="center">
          <Grid container style={{ width: '100%' }}>
            <Grid item style={{ width: '100%' }}>
              <HeaderWrapper>
                <Heading1>{i18n.t('signin-SignIn-signIn')}</Heading1>
              </HeaderWrapper>
              {signInError && (
                <MessageContainer>
                  <Message className="active">{signInError}</Message>
                </MessageContainer>
              )}
            </Grid>

            <Grid container>
              <InputGridWrapper item>
                <TextInput
                  label={i18n.t('signin-SignIn-emailAddress')}
                  error={username.error}
                  dataTestId="username-input"
                  value={username.value}
                  onChange={(e) =>
                    updateField({ value: e.target.value, name: 'username' })
                  }
                  onKeyPress={handleOnKeyPress}
                  onFocus={() => username.setFocus(true)}
                  onBlur={() => username.setFocus(false)}
                  name="username"
                  id="username"
                />
              </InputGridWrapper>
            </Grid>

            <Grid style={{ marginTop: '-12px' }} container>
              <InputGridWrapper item>
                <TextInput
                  type={passwordFieldType}
                  label={i18n.t('signin-SignIn-password')}
                  error={password.error}
                  dataTestId="password-input"
                  value={password.value}
                  onKeyPress={handleOnKeyPress}
                  onChange={(e) =>
                    updateField({ value: e.target.value, name: 'password' })
                  }
                  onFocus={() => password.setFocus(true)}
                  onBlur={() => password.setFocus(false)}
                  name="password"
                  id="password"
                  iconRight
                  icon={
                    <PasswordIcon
                      onClick={handlePasswordIconToggle}
                      className={
                        passwordIconType === 'hidden'
                          ? 'ri-eye-off-line'
                          : 'ri-eye-line'
                      }
                    />
                  }
                />
              </InputGridWrapper>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button
                  dataTestId="sign-in-button"
                  onClick={handleSubmitPress}
                  disabled={isVisitedForm && isSubmitting}
                >
                  {i18n.t('signin-SignIn-signIn')}
                </Button>
              </Grid>
              <Grid item>
                <Button type="link" onClick={() => history.push('/forgot')}>
                  {i18n.t('signin-SignIn-forgotPassword')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
  return (
    <div>
      <Loading size="sm" />
    </div>
  );
};

export default SignIn;
