import { I18nError } from './constants';
import type { I18NType } from './types';

const handleSetAttachmentError = (
  i18n: I18NType,
  dispatch: (action: unknown) => void,
  openSnackbar: (message: string, type: string) => unknown,
  error: unknown
) => {
  if (error instanceof I18nError) {
    dispatch(
      openSnackbar(
        i18n.t(error.i18n_id, {
          ...error.i18n_variables,
          defaultValue: error.i18n_id,
        }),
        'error'
      )
    );
  } else {
    console.error('Error attaching file to task:', error);
  }
};

export default handleSetAttachmentError;
