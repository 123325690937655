import React from 'react';
import i18n from 'i18n-js';
import {
  TableHead,
  Checkbox,
  EmphasisText,
  Button,
  Avatar,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  ContactDataWrapper,
  getStyledNameText,
  AvatarWrapper,
  StyledTableRow,
  StyledCheckboxTD,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { addSelectedDataTestId, getSortOptions } from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll.js';
import THEMES from '../../../../styles/themes/app.js';
import type { Group } from '../../../../utils/helpers/types';

interface GroupsTableProps {
  groups: Array<Group>;
  selectedAudience?: {
    groupIds: string[];
    remove?: boolean;
  };
  loading: boolean;
  loadMoreRows: () => void;
  onAdd: (args: { groupIds: string[]; remove?: boolean }) => void;
  onSort: (args: { label: string; value: string }) => void;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount?: number;
  };
  sort: {
    label: string;
    value: string;
  };
  heightDifference?: number;
  disableCheckboxes?: boolean;
  type?: string;
}

const GroupsTable = ({
  groups,
  selectedAudience = {
    groupIds: [],
  },
  loading,
  loadMoreRows,
  onAdd,
  onSort,
  pageInfo,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
  type = '',
}: GroupsTableProps) => {
  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, shouldCheckItem } =
    useMultiSelectTableItems({
      items: groups,
    });

  const shouldItemBeChecked = (id: string) => {
    if (type === 'TASK') {
      return selectedAudience?.groupIds?.includes(id);
    }
    return shouldCheckItem(id);
  };

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ groupIds: selectedItems });
    handleSelectNone();
  };

  const onCheckItem = (id: string) => {
    if (type === 'TASK') {
      const isSelected = selectedAudience.groupIds.includes(id);
      onAdd({
        groupIds: [id],
        remove: isSelected,
      });
    }
    handleCheckItem(id);
  };

  /**
   * For accessibility, if the whole row is clickable for the select action,
   * then it must be tabbable and hitting the enter key should serve as a click
   * @param {*} e KeyBoard Event
   * @param {string} id Group ID
   */
  const handleKeyDown = (e: React.KeyboardEvent, id: string) => {
    if (e.key === 'Enter') {
      onCheckItem(id);
    }
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitSort={onSort}
        />
        {type !== 'TASK' ? (
          <Button
            dataTestId={`groups-table-${addSelectedDataTestId}`}
            onClick={() => handleAdd()}
            disabled={selectedItems.length === 0 || disableCheckboxes}
          >
            {i18n.t('broadcasts-broadcast-addSelected', {
              defaultValue: 'Add %{number} Selected',
              number: selectedItems.length,
            })}
          </Button>
        ) : null}
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <th>
              {i18n.t('broadcasts-broadcastList-groupName', {
                defaultValue: 'Group Name',
              })}
            </th>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={groups}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: { list: Array<Group>; index: number }) => {
          const { list, index } = args;
          const group = list[index] as Group;
          return (
            <StyledTableRow
              key={group.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disableCheckboxes) return;
                onCheckItem(group.id);
              }}
              onKeyDown={(e) => {
                if (disableCheckboxes) return;
                handleKeyDown(e, group.id);
              }}
            >
              <StyledCheckboxTD>
                <Checkbox
                  checked={shouldItemBeChecked(group.id)}
                  dataTestId="create-broadcastList-checkboxGroup"
                  onCheck={() => {
                    /* Not needed - entire table row is clickable */
                  }}
                  disabled={disableCheckboxes}
                />
                <AvatarWrapper>
                  <Avatar size="md" icon="tag" disabled={disableCheckboxes} />
                  <ContactDataWrapper>
                    <EmphasisText
                      customStyle={getStyledNameText}
                      disabled={disableCheckboxes}
                    >
                      {group.name}
                    </EmphasisText>
                  </ContactDataWrapper>
                </AvatarWrapper>
              </StyledCheckboxTD>
            </StyledTableRow>
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

export default GroupsTable;
