import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { LABELS_MINIMAL_QUERY } from 'client-lib';
import { useSelector } from 'react-redux';
import { ChipSelect } from '../../lib/patterns';
import type { OptionType } from '../../lib/patterns/ChipSelect/types';
import type { AppState, Label } from '../../utils/helpers/types';

const LabelFormInputWrapper = styled.div<{
  marginTop: string;
  marginBottom: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

export interface LabelFormInputProps {
  labelValues: OptionType[];
  setLabelValues: (labels: Partial<Label>[]) => void;
  marginTop?: string;
  marginBottom?: string;
  key: string;
  dataTestId?: string;
  [key: string]: unknown; // This allows for any other props to be passed to the component for rest props without the parent showing TS warnings
}

/**
 * LabelFormInput component.
 *
 * @component
 * @param labelValues - Array of label values.
 * @param marginBottom - Margin bottom value.
 * @param marginTop - Margin top value.
 * @param setLabelValues - Sends selected values to parent component
 * @param props - Props passed down to ChipSelect component.
 * @returns {JSX.Element} LabelFormInput component.
 */
const LabelFormInput = ({
  labelValues = [],
  key,
  dataTestId,
  marginBottom = '0px',
  marginTop = '8px',
  setLabelValues,
  ...props
}: LabelFormInputProps): JSX.Element => {
  const client = useApolloClient();
  const autoFocusField = useSelector(
    (state: AppState) => state?.general?.contactSlideoutAutofocusField
  );
  const editLabelStatus = useSelector(
    (state: AppState) => state?.editCustomer?.triggerEditContactLabels
  );

  const { data, loading, error } = useQuery(LABELS_MINIMAL_QUERY, {
    client,
    variables: { limit: 100 },
  });

  let inputError = '';
  let labels: OptionType[] = [];

  if (data?.labels?.edges.length) {
    labels = data.labels.edges.map((edgeNode: { node: Label }) => ({
      text: edgeNode.node.name,
      value: edgeNode.node.id,
      color: edgeNode.node.color,
    }));
  }

  if (error) inputError = i18n.t('announcements-Announcements-error');

  return (
    <LabelFormInputWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <ChipSelect
        key={key}
        dataTestId={
          dataTestId || 'label-EditCustomerContactDetails-labelSelector'
        }
        label={i18n.t('settings-Label-labels', {
          defaultValue: 'Labels',
        })}
        onChange={(val) => setLabelValues(val as unknown as Partial<Label>[])}
        placeholder={i18n.t('settings-Label-searchLabels', {
          defaultValue: 'Search Labels',
        })}
        loading={loading}
        options={labels}
        value={labelValues}
        error={inputError}
        openOnInit={editLabelStatus || autoFocusField === 'labels'}
        {...props}
      />
    </LabelFormInputWrapper>
  );
};

export default LabelFormInput;
