import React, { useState } from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  CONTACTS_QUERY,
  GROUPS_V2_NAMES_QUERY,
  LIST_TAG_AUDIENCE_INFO_QUERY,
  LABELS_QUERY,
  LIST_TAG_AUDIENCE_COUNT_QUERY,
  usePaginated,
} from 'client-lib';

import { useSelector } from 'react-redux';
import CreateBroadcastListTemplate from '../../CreateBroadcastListTemplate';
import { TabList } from '../../../../../elements';
import ContactsTable from '../../../shared/AudienceBuilderTables/ContactsTable.tsx';
import SearchBar from '../../../../SearchBar/SearchBar';
import GroupsTable from '../../../shared/AudienceBuilderTables/GroupsTable.tsx';
import { Container, Header, customInputStyle } from './styles';
import SelectedAudienceTable from '../../../shared/AudienceBuilderTables/SelectedAudienceTable.tsx';
import {
  addSelectedDataTestId,
  getSortOptions,
} from '../../../shared/utils.ts';
import LabelsTable from '../../../shared/AudienceBuilderTables/LabelsTable.tsx';
import ExitConfirmationModal from '../../../shared/AudienceBuilderTables/subComponents/ExitConfirmationModal.tsx';

export const TABS = {
  CONTACTS: 'contacts',
  GROUPS: 'groups',
  LABELS: 'labels',
  SELECTED: 'selected',
};

const AudienceBuilder = ({ closeWizard, wizardGlobalProps, setWizardPage }) => {
  const {
    search,
    setSearch,
    tag,
    tagId,
    handleOnSaveSelectedAudience,
    handleEditTag,
    isEdit,
  } = wizardGlobalProps;

  const hasLabelsFF = useSelector(
    (state) => state.accountData.account?.ff_contact_labels
  );

  // Sort States
  const sortOptions = getSortOptions(i18n);
  const ascending = sortOptions[0];
  const [contactSort, setContactSort] = useState(ascending);
  const [groupSort, setGroupSort] = useState(ascending);
  const [selectedSort, setSelectedSort] = useState(ascending);
  const [labelsSort, setLabelsSort] = useState(ascending);

  // Filter States
  const [contactFilters, setContactFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  // All Other States
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    !isEdit ? TABS.CONTACTS : TABS.SELECTED
  );

  const client = useApolloClient();

  // Data for Contacts tab
  const {
    contacts: contactsData,
    handleFetchMore: fetchContacts,
    loading: contactsLoading,
    pageInfo: contactPageInfo,
    refetch: refetchContacts,
  } = usePaginated({
    client,
    filter: search,
    key: 'contacts',
    query: CONTACTS_QUERY,
    queryVariables: {
      notWithTagIds: [tagId],
      sortType: contactSort.value,
      ...contactFilters,
    },
  });

  // Data for Groups tab
  const {
    groupsV2: groupsData,
    handleFetchMore: fetchGroups,
    loading: groupsLoading,
    pageInfo: groupPageInfo,
    refetch: refetchGroups,
  } = usePaginated({
    client,
    query: GROUPS_V2_NAMES_QUERY,
    key: 'groupsV2',
    filter: search,
    queryVariables: {
      notWithTagIds: [tagId],
      sortType: groupSort.value,
      relayStylePagination: true,
    },
  });

  // Data for Selected Audience counts
  const { data: selectedAudienceCount, refetch: refetchAudienceCount } =
    useQuery(LIST_TAG_AUDIENCE_COUNT_QUERY, {
      client,
      key: 'listTagAudienceCount',
      variables: {
        tagId,
        filter: search,
        ...selectedFilters,
      },
    });

  // Data for Selected Audience tab
  const {
    handleFetchMore: fetchAudience,
    listTagAudienceInfo: selectedAudience,
    loading: audienceLoading,
    pageInfo: audiencePageInfo,
    refetch: refetchAudience,
  } = usePaginated({
    client,
    filter: search,
    key: 'listTagAudienceInfo',
    query: LIST_TAG_AUDIENCE_INFO_QUERY,
    queryVariables: {
      tagId,
      filter: search,
      sortType: selectedSort.value,
      ...selectedFilters,
    },
  });

  // Data for labels tab
  const {
    handleFetchMore: fetchLabels,
    labels: labelsData,
    loading: labelsLoading,
    pageInfo: labelsPageInfo,
    refetch: refetchLabels,
  } = usePaginated({
    client,
    filter: search,
    key: 'labels',
    query: LABELS_QUERY,
    queryVariables: {
      notWithTagIds: [tagId],
      sortType: labelsSort.value,
      relayStylePagination: true,
    },
  });

  // Selects an active tab and unsets any active filters
  const handleTabSelect = ({ value }) => {
    setSelectedTab(value);
    setContactSort(ascending);
    setGroupSort(ascending);
    setSelectedSort(ascending);
    setContactFilters({});
    setSelectedFilters({});
    setSearch('');
  };

  const getTabs = (selected = 0) =>
    hasLabelsFF
      ? [
          {
            label: i18n.t('customers-CustomerList-contacts', {
              defaultValue: 'Contacts',
            }),
            value: TABS.CONTACTS,
          },
          {
            label: i18n.t('settings-Groups-groups', {
              defaultValue: 'Groups',
            }),
            value: TABS.GROUPS,
          },
          {
            label: i18n.t('settings-Label-labels', {
              defaultValue: 'Labels',
            }),
            value: TABS.LABELS,
          },
          {
            label: i18n.t('broadcasts-broadcast-selectedAudience', {
              defaultValue: 'Selected Audience (%{number})',
              number:
                selectedAudienceCount?.listTagAudienceCount?.count || selected,
            }),
            value: TABS.SELECTED,
          },
        ]
      : [
          {
            label: i18n.t('customers-CustomerList-contacts', {
              defaultValue: 'Contacts',
            }),
            value: TABS.CONTACTS,
          },
          {
            label: i18n.t('settings-Groups-groups', {
              defaultValue: 'Groups',
            }),
            value: TABS.GROUPS,
          },
          {
            label: i18n.t('broadcasts-broadcast-selectedAudience', {
              defaultValue: 'Selected Audience (%{number})',
              number:
                selectedAudienceCount?.listTagAudienceCount?.count || selected,
            }),
            value: TABS.SELECTED,
          },
        ];

  const activeTabs = getTabs(selectedAudience?.length).map((tab) => ({
    ...tab,
    active: selectedTab === tab.value,
  }));

  /**
   * Handles adding and removing contacts/companies/groups to lists
   * @param {object} selected array of contacts/companies/groups from one of the above tabs
   * @param {bool} remove flag to either add or remove the selected contacts/companies/groups
   */
  const handleListRecipientsUpdate = async ({
    companyIds = [],
    contactIds = [],
    groupIds = [],
    labelIds = [],
    remove = false,
  }) => {
    if (!remove) {
      await handleOnSaveSelectedAudience(
        { contactIds, groupIds, companyIds, labelIds },
        { contactIds: [], groupIds: [], companyIds: [], labelIds: [] },
        true
      );
    } else {
      await handleOnSaveSelectedAudience(
        { contactIds: [], groupIds: [], companyIds: [], labelIds: [] },
        { contactIds, groupIds, companyIds, labelIds },
        false
      );
    }
    // clears filters
    handleTabSelect({ value: selectedTab });
    if (selectedTab === TABS.CONTACTS) refetchContacts();
    else if (selectedTab === TABS.GROUPS) refetchGroups();
    else if (selectedTab === TABS.LABELS) refetchLabels();
    // always refetch audience in order to get an updated tab title
    refetchAudience();
    refetchAudienceCount();
  };

  // Maps selected filter options into an array of IDs
  const handleSubmitFilter = (filters) => {
    if (selectedTab === TABS.CONTACTS) {
      setContactFilters(filters);
    } else if (selectedTab === TABS.SELECTED) {
      setSelectedFilters(filters);
    }
  };

  const handleSubmitSort = (sort) => {
    if (selectedTab === TABS.CONTACTS) {
      setContactSort(sort);
    } else if (selectedTab === TABS.GROUPS) {
      setGroupSort(sort);
    } else if (selectedTab === TABS.SELECTED) {
      setSelectedSort(sort);
    } else if (selectedTab === TABS.LABELS) {
      setLabelsSort(sort);
    }
  };

  const handleContinue = () => {
    closeWizard('Saved');
  };

  const handleContinueCheck = () => {
    const el = document.querySelector(
      `[data-testid*='${addSelectedDataTestId}']`
    );
    const currentSelection = el?.textContent.split(' ')[1];
    if (currentSelection && +currentSelection > 0) {
      setIsExitModalOpen(true);
    } else {
      handleContinue();
    }
  };

  const backStep = () => setWizardPage('CreateBroadcastListPage');

  return (
    <CreateBroadcastListTemplate
      title={tag?.name}
      continueButtonText={i18n.t('slideouts-GroupMessageRecipients-saveExit')}
      continueButtonAction={handleContinueCheck}
      backButtonAction={backStep}
      flyoutPrimaryButton={(name, descr) => handleEditTag(name, descr)}
      showIcon={isEdit}
      isEdit={isEdit}
      tag={tag}
    >
      <Container>
        <ExitConfirmationModal
          dataTestId="btm-list"
          isOpen={isExitModalOpen}
          onCancel={() => setIsExitModalOpen(false)}
          onSave={handleContinue}
        />
        <Header>
          <TabList
            dataTestId="create-broadcastList-tabs"
            options={activeTabs}
            onClick={handleTabSelect}
          />
          <SearchBar
            dataTestId="create-broadcastList-search"
            value={search}
            setValue={(e) => setSearch(e)}
            placeholder={i18n.t('search-Search-search')}
            hideBottomSpace
            customContainerStyle={customInputStyle}
          />
        </Header>
        {selectedTab === TABS.CONTACTS && (
          <ContactsTable
            contacts={contactsData || []}
            onAdd={handleListRecipientsUpdate}
            onFilter={handleSubmitFilter}
            onSort={handleSubmitSort}
            filters={contactFilters}
            sort={contactSort}
            pageInfo={contactPageInfo}
            loading={contactsLoading}
            loadMoreRows={fetchContacts}
            type="list"
            hasLabelsFF={hasLabelsFF}
          />
        )}
        {selectedTab === TABS.GROUPS && (
          <GroupsTable
            groups={groupsData || []}
            onAdd={handleListRecipientsUpdate}
            onSort={handleSubmitSort}
            sort={groupSort}
            pageInfo={groupPageInfo}
            loading={groupsLoading}
            loadMoreRows={fetchGroups}
          />
        )}
        {selectedTab === TABS.LABELS && (
          <LabelsTable
            labels={labelsData || []}
            onAdd={handleListRecipientsUpdate}
            onSort={handleSubmitSort}
            sort={labelsSort}
            pageInfo={labelsPageInfo}
            loading={labelsLoading}
            loadMoreRows={fetchLabels}
          />
        )}
        {selectedTab === TABS.SELECTED && (
          <SelectedAudienceTable
            selectedAudience={selectedAudience || []}
            onRemove={handleListRecipientsUpdate}
            onFilter={handleSubmitFilter}
            onSort={handleSubmitSort}
            filters={selectedFilters}
            sort={selectedSort}
            pageInfo={audiencePageInfo}
            loading={audienceLoading}
            loadMoreRows={fetchAudience}
          />
        )}
      </Container>
    </CreateBroadcastListTemplate>
  );
};

AudienceBuilder.propTypes = {
  wizardGlobalProps: PropTypes.shape({
    handleCreateTag: PropTypes.func,
    handleInputTagChange: PropTypes.func,
    handleEditTag: PropTypes.func,
    handleOnSaveSelectedAudience: PropTypes.func,
    search: PropTypes.string,
    setSearch: PropTypes.func,
    setTagDescr: PropTypes.func,
    setTagId: PropTypes.func,
    startingTab: PropTypes.string,
    tag: PropTypes.shape({
      name: PropTypes.string,
      error: PropTypes.string,
      description: PropTypes.string,
      descriptionError: PropTypes.string,
    }),
    tagId: PropTypes.string,
    title: PropTypes.string,
    isEdit: PropTypes.bool,
  }).isRequired,
  setWizardPage: PropTypes.func.isRequired,
  closeWizard: PropTypes.func.isRequired,
};

export default AudienceBuilder;
