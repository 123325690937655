import type { TaskForm } from "../types";

export const REQUIRED_FIELDS: (keyof TaskForm)[] = [
  "dueDate",
  "group",
  "name",
  "recipient",
];

export const ASSIGNEE_DEFAULT_VALUE = {
  value: "",
  label: "Unassigned",
};
