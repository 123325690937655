export const SET_PRINT_LOADING_STATE = 'SET_CREATE_LABEL_FLYOUT';
export const SET_DONE_LOADING = 'SET_DONE_LOADING';
export const setPrintLoadingState = (value: string) => ({
  type: SET_PRINT_LOADING_STATE,
  value,
});

export const setDoneLoading = (value: string) => ({
  type: SET_DONE_LOADING,
  value,
});
