import type {
  CustomerContact,
  PageInfo,
  Thread,
} from '../../../utils/helpers/types';

// ENUMS

/**
 * Determines what components should be displayed to the right
 * of the contact list items
 */
export enum RightSideComponent {
  FailureCode = 'FAIL',
  ResponseView = 'RESPONSE',
}

/**
 * Determines which view to display in the Broadcast DetailsFlyout
 */
export enum ViewTypeOptions {
  All = 'all',
  Clicked = 'clicked',
  Failed = 'failed',
  Main = 'main',
  Responded = 'responded',
  ResponseMessage = 'message',
  Unsubscribed = 'unsubscribed',
  Sent = 'sent',
}

// TYPES
/**
 * Query response types
 */
export type Recipient = {
  node: {
    contact: CustomerContact;
    failureCode: string;
    responseThread?: Thread;
  };
};

export type RecipientDataEdge = Recipient & {
  cursor: string;
};

export type RecipientData = {
  announcementRecipients: {
    edges: RecipientDataEdge[];
    pageInfo: PageInfo;
  };
};
