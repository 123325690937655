import { useState } from 'react';

import { MULTI_SELECT_FILTER } from './helpers.ts';

/*
DOCUMENTATION
  this hook was designed to be used inside useMultiSelectTableItems and useSelectAll hooks. 
*/

const useMultiSelectItems = ({ items }) => {
  const [selectedItems, setSelectItems] = useState([]);
  const [deselectedItems, setDeselectedItems] = useState([]);
  const [filterSelect, setFilterSelect] = useState(MULTI_SELECT_FILTER.NONE);

  const handleCheckItem = (itemId) => {
    if (filterSelect === MULTI_SELECT_FILTER.ALL) {
      const updatedDeselectedItems = [...deselectedItems];

      if (updatedDeselectedItems.includes(itemId)) {
        const index = updatedDeselectedItems.indexOf(itemId);
        updatedDeselectedItems.splice(index, 1);
      } else {
        updatedDeselectedItems.push(itemId);
      }

      setDeselectedItems(updatedDeselectedItems);

      return;
    }

    const updatedSelectedContacts = [...selectedItems];

    if (updatedSelectedContacts.includes(itemId)) {
      const index = updatedSelectedContacts.indexOf(itemId);
      updatedSelectedContacts.splice(index, 1);
      setFilterSelect(MULTI_SELECT_FILTER.NONE);
    } else {
      updatedSelectedContacts.push(itemId);
    }
    setSelectItems(updatedSelectedContacts);
  };

  const shouldCheckItem = (itemId) => {
    if (filterSelect === MULTI_SELECT_FILTER.ALL) {
      return !deselectedItems.includes(itemId);
    }
    return selectedItems.includes(itemId);
  };

  const handleCheck = () => {
    if (filterSelect === MULTI_SELECT_FILTER.ALL) {
      handleSelectNone();
    }
    if (filterSelect === MULTI_SELECT_FILTER.NONE) {
      handleSelectAll();
    }
  };

  const handleSelectNone = () => {
    setSelectItems([]);
    setDeselectedItems([]);
    setFilterSelect(MULTI_SELECT_FILTER.NONE);
  };

  const handleSelectAll = () => {
    setSelectItems([]);
    setFilterSelect(MULTI_SELECT_FILTER.ALL);
  };

  return {
    handleCheck,
    handleCheckItem,
    shouldCheckItem,
    indeterminate:
      filterSelect !== MULTI_SELECT_FILTER.ALL && selectedItems.length > 0,
    allItemsSelected: filterSelect === MULTI_SELECT_FILTER.ALL,
    handleSelectAll,
    handleSelectNone,
    filterSelect,
    setFilterSelect,
    selectedItems,
    setSelectItems,
    deselectedItems,
    setDeselectedItems,
    disabled: items.length === 0,
  };
};

export default useMultiSelectItems;
