/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  EmphasisText as EmphasisTextJS,
  Heading1 as Heading1JS,
  Heading2 as Heading2JS,
  Heading3 as Heading3JS,
  Heading4 as Heading4JS,
  Heading5 as Heading5JS,
  ItalicText as ItalicTextJS,
  MetaText as MetaTextJS,
  Span as SpanJS,
  Text as TextJS,
} from './Text/Text';
import AlertMessageBoxJS from './AlertMessageBox/AlertMessageBox';
import AnchorJS from './Anchor/Anchor';
import AttachmentTileJS from './AttachmentTile/AttachmentTile';
import AvatarJS from './Avatar/Avatar';
import ButtonJS from './Button/Button';
import ButtonSelectorJS from './Button/ButtonSelector';
import CardJS from './Card/Card';
import ChannelButtonSelectorJS from './Button/ChannelButtonSelector';
import CheckboxJS from './Checkbox/Checkbox';
import ChipJS from './Chip/Chip';
import ChipLabelJS from './Chip/ChipLabel';
import EntityCardJS from './EntityCard/EntityCard';
import IconButtonJS from './Button/IconButton';
import IconLabelJS from './Button/IconLabel';
import InfiniteSuggestJS from './AutoSuggest/InfiniteSuggest';
import InlineTextButtonJS from './Button/InlineTextButton';
import InternalUseOnlyJS from './InternalUseOnly/InternalUseOnly';
import LoadingJS from './Loading/Loading';
import ModalJS from './Modal/Modal';
import OptionJS from './Option/Option';
import OptionButtonJS from './Button/OptionButton';
import SelectDateJS from './Select/SelectDate';
import SelectedEntityJS from './EntityCard/SelectedEntity';
import SelectJS from './Select/Select';
import SortButtonJS from './Table/SortButton';
import SquareBadgeJS from './Badge/SquareBadge';
import SwitchJS from './Switch/Switch';
import TableBodyJS from './Table/TableBody';
import TableHeadJS from './Table/TableHead';
import TableJS from './Table/Table';
import TabListJS from './TabList/TabList';
import TextAreaJS from './TextArea/TextArea';
import TextInputJS from './TextInput/TextInput';
import TooltipJS from './Tooltip/Tooltip';
import useMultiSelectTableItemsJS from './Select/MultiSelectTableItems/useMultiSelectTableItems';
import WidgetJS from './Widget/Widget';

export const AlertMessageBox: any = AlertMessageBoxJS;
export const Anchor: any = AnchorJS;
export const AttachmentTile: any = AttachmentTileJS;
export const Avatar: any = AvatarJS;
export const Button: any = ButtonJS;
export const ButtonSelector: any = ButtonSelectorJS;
export const Card: any = CardJS;
export const ChannelButtonSelector: any = ChannelButtonSelectorJS;
export const Checkbox: any = CheckboxJS;
export const Chip: any = ChipJS;
export const ChipLabel: any = ChipLabelJS;
export const EmphasisText: any = EmphasisTextJS;
export const EntityCard: any = EntityCardJS;
export const Heading1: any = Heading1JS;
export const Heading2: any = Heading2JS;
export const Heading3: any = Heading3JS;
export const Heading4: any = Heading4JS;
export const Heading5: any = Heading5JS;
export const IconButton: any = IconButtonJS;
export const IconLabel: any = IconLabelJS;
export const InfiniteSuggest: any = InfiniteSuggestJS;
export const InlineTextButton: any = InlineTextButtonJS;
export const InternalUseOnly: any = InternalUseOnlyJS;
export const ItalicText: any = ItalicTextJS;
export const Loading: any = LoadingJS;
export const MetaText: any = MetaTextJS;
export const Modal: any = ModalJS;
export const Option: any = OptionJS;
export const OptionButton: any = OptionButtonJS;
export const Select: any = SelectJS;
export const SelectDate: any = SelectDateJS;
export const SelectedEntity: any = SelectedEntityJS;
export const SortButton: any = SortButtonJS;
export const Span: any = SpanJS;
export const SquareBadge: any = SquareBadgeJS;
export const Switch: any = SwitchJS;
export const Table: any = TableJS;
export const TableBody: any = TableBodyJS;
export const TableHead: any = TableHeadJS;
export const TabList: any = TabListJS;
export const Text: any = TextJS;
export const TextArea: any = TextAreaJS;
export const TextInput: any = TextInputJS;
export const Tooltip: any = TooltipJS;
export const useMultiSelectTableItems: any = useMultiSelectTableItemsJS;
export const Widget: any = WidgetJS;

// TS-original
export { default as Snackbar } from './Snackbar/Snackbar';
export { default as InputLabel } from './inputCommonElements/InputLabel';
export { default as InputError } from './inputCommonElements/InputError';
export { default as FilterBy } from './FilterBy/FilterBy';
export { default as AsyncSelectPaginate } from './Select/AsyncSelectPaginate/AsyncSelectPaginate';
export { default as AsyncSelect } from './Select/AsyncSelect/AsyncSelect';
