import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useReadThreadsFromApolloCache } from 'client-lib';
import {
  getAvailableThemes,
  THEME_MODES,
} from 'client-lib/src/lib/utils/helpers';
import { useApolloClient } from '@apollo/client';
import SettingsSidebar from './SettingsSidebar';
import { setActiveSidebar } from '../../actions/general';
import CreateSidebar from './CreateSidebar';
import { Badge, Button } from '../../elements';
import THEMES from '../../styles/themes/app';
import {
  Wrapper,
  BadgeWrap,
  IconWrap,
  createButtonStyle,
  iconButtonStyle,
  SidebarWrap,
  AvatarWrap,
  WhitelabelLogoWrap,
  WhitelabelImg,
  ListCheckTask3Icon,
} from './styles';
import { setThreadCounts } from '../../actions';
import useWhitelabel from './useWhitelabel';
import useSetThreadCounts from '../../hooks/useSetThreadCounts';
import { navigateHome } from './utils';
import AppLogo from './AppLogo.tsx';

// removes icons from messing with printer preview
const NoPrintIcon = styled.i`
  @media print {
    display: none;
  }
`;

const SidebarBadge = ({ children, active, ...rest }) => (
  <BadgeWrap>
    <Badge
      badgeBorderColor={
        active
          ? THEMES.SIDEBAR_ICON_ACTIVE_BACKGROUND
          : THEMES.COLORED_BACKGROUND_PRIMARY
      }
      {...rest}
    >
      {children}
    </Badge>
  </BadgeWrap>
);
SidebarBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
};
SidebarBadge.defaultProps = { children: null, active: false };

const Sidebar = ({
  announcementsPagePermission,
  dashboardPagePermission,
  feedbackPagePermission,
  reportsPagePermission,
  taskPagePermission,
}) => {
  const theme = useSelector((state) => state?.session?.currentUser?.theme);
  const availableThemes = getAvailableThemes();
  const mode = availableThemes.includes(theme) ? theme : THEME_MODES.light;
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const threadInfo = useSelector((state) => state?.threadInfo);
  const general = useSelector((state) => state?.general);
  const customerInfo = useSelector((state) => state?.customerInfo);
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const includeInternal = useSelector(
    (state) => state.accountData.account?.ff_internal_threads
  );

  const { whitelabelLogo } = useWhitelabel({ client, currentUser });

  const { pathname } = location;

  const { contactId, userId, groupInboxNotificationsEnabled } = currentUser;

  const showThreadNotification =
    groupInboxNotificationsEnabled === false
      ? threadInfo?.threadCounts?.unreadThreadCount
      : threadInfo?.threadCounts?.unclaimedThreadCount ||
        threadInfo?.threadCounts?.unreadThreadCount;

  let threadBadge = null;
  if (!showThreadNotification) {
    threadBadge = null;
  } else {
    threadBadge = (
      <SidebarBadge
        active={pathname.indexOf('/threads') === 0}
        data-testid="threads-badge"
      />
    );
  }

  let threadsUrl = `/threads/${threadInfo.filter}`;

  if (threadInfo.activeThreadId && threadInfo.filter === 'open') {
    threadsUrl += `/${threadInfo.activeThreadId}`;
  } else if (
    threadInfo.activeUnclaimedThreadId &&
    threadInfo.filter === 'inbox'
  ) {
    threadsUrl += `/${threadInfo.activeUnclaimedThreadId}`;
  }

  let customersUrl = `/contacts/${customerInfo.filter}`;
  if (customerInfo.activeCustomerId) {
    customersUrl += `/${customerInfo.activeCustomerId}`;
  }

  const { inboxThreads, myOpenThreads } = useReadThreadsFromApolloCache({
    client,
    threadsActiveGroupIds,
    contactId,
    userId,
    includeInternal,
  });

  useSetThreadCounts({
    contactId,
    inboxThreads,
    myOpenThreads,
    setThreadCounts: (payload) => dispatch(setThreadCounts(payload)),
  });

  // this is to mitigate discrepencies between the url and the active sidebar when they do not match.
  useEffect(() => {
    if (
      general.activeSidebar === 'settings' &&
      pathname.indexOf('/settings') !== 0
    ) {
      dispatch(setActiveSidebar('default'));
    }
  }, [pathname]);

  if (general.activeSidebar === 'hidden') return null;

  return (
    <SidebarWrap data-testid="sidebar">
      {general.activeSidebar === 'settings' ? (
        <SettingsSidebar
          history={history}
          whitelabelLogo={whitelabelLogo}
          dashboardPagePermission={dashboardPagePermission}
          feedbackPagePermission={feedbackPagePermission}
          onExit={() => dispatch(setActiveSidebar('default'))}
        />
      ) : general.activeSidebar === 'create' ? (
        <CreateSidebar
          currentUser={currentUser}
          activeSidebar={general.activeSidebar}
          dispatchActiveSidebar={(value) => dispatch(setActiveSidebar(value))}
          showAnnouncementsNavItem={announcementsPagePermission}
        />
      ) : (
        <Wrapper>
          {whitelabelLogo ? (
            <WhitelabelLogoWrap>
              <WhitelabelImg
                src={whitelabelLogo}
                onClick={() =>
                  navigateHome(
                    dashboardPagePermission,
                    feedbackPagePermission,
                    history
                  )
                }
              />
            </WhitelabelLogoWrap>
          ) : (
            <AvatarWrap
              data-testid="sidebar-avatar"
              onClick={() =>
                navigateHome(
                  dashboardPagePermission,
                  feedbackPagePermission,
                  history
                )
              }
            >
              <AppLogo />
            </AvatarWrap>
          )}
          <IconWrap>
            <Button
              type="noStyle"
              size="zero"
              noOutline
              title={i18n.t('sidebar-CreateSidebar-create', {
                defaultValue: 'Create',
              })}
              onClick={() => dispatch(setActiveSidebar('create'))}
              customStyle={createButtonStyle}
              dataTestId="create-button"
            >
              <NoPrintIcon className="ri-edit-box-line" />
            </Button>
          </IconWrap>
          {dashboardPagePermission && (
            <IconWrap
              isTop
              active={
                pathname.indexOf('/dashboard') === 0 ||
                pathname.indexOf('/feedback') === 0
              }
            >
              <NavLink
                to="/dashboard"
                title={i18n.t('settings-Dashboard-dashboard', {
                  defaultValue: 'Dashboard',
                })}
                data-testid="dashboard-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={
                    pathname.indexOf('/dashboard') === 0 ||
                    pathname.indexOf('/feedback') === 0
                  }
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-dashboard-line" />
                </Button>
              </NavLink>
            </IconWrap>
          )}
          {!dashboardPagePermission && feedbackPagePermission && (
            <IconWrap isTop active={pathname.indexOf('/feedback') === 0}>
              <NavLink
                to="/feedback"
                title={i18n.t('settings-Support-feedback', {
                  defaultValue: 'Feedback',
                })}
                data-testid="dashboard-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/feedback') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-dashboard-line" />
                </Button>
              </NavLink>
            </IconWrap>
          )}
          <IconWrap
            isTop={!dashboardPagePermission}
            active={pathname.indexOf('/threads') === 0}
          >
            <NavLink
              to={threadsUrl}
              title={i18n.t('threads-Threads-inbox', { defaultValue: 'Inbox' })}
              data-testid="threads-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/threads') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-inbox-fill" />
                {threadBadge}
              </Button>
            </NavLink>
          </IconWrap>

          {taskPagePermission && (
            <IconWrap active={pathname.indexOf('/tasks') === 0}>
              <NavLink
                to="/tasks"
                title={i18n.t('tasks-Tasks-header', {
                  defaultValue: 'Tasks',
                })}
                data-testid="tasks-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/tasks') === 0}
                  customStyle={iconButtonStyle}
                >
                  <ListCheckTask3Icon active={pathname.indexOf('/tasks') === 0}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fill={
                          pathname.indexOf('/tasks') === 0 &&
                          mode === THEME_MODES.dark
                            ? 'rgb(87, 167, 185)'
                            : 'rgb(255, 237, 209)'
                        }
                        d="M8.00008 6V9H5.00008V6H8.00008ZM3.00008 4V11H10.0001V4H3.00008ZM13.0001 4H21.0001V6H13.0001V4ZM13.0001 11H21.0001V13H13.0001V11ZM13.0001 18H21.0001V20H13.0001V18ZM10.7072 16.2071L9.29297 14.7929L6.00008 18.0858L4.20718 16.2929L2.79297 17.7071L6.00008 20.9142L10.7072 16.2071Z"
                      />
                    </svg>
                  </ListCheckTask3Icon>
                </Button>
              </NavLink>
            </IconWrap>
          )}

          <IconWrap active={pathname.indexOf('/contacts') === 0}>
            <NavLink
              to={customersUrl}
              title={i18n.t('customers-CustomerList-contacts', {
                defaultValue: 'Contacts',
              })}
              data-testid="contacts-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/contacts') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-contacts-book-2-fill" />
              </Button>
            </NavLink>
          </IconWrap>

          {announcementsPagePermission && (
            <IconWrap active={pathname.indexOf('/broadcasts') === 0}>
              <NavLink
                to="/broadcasts"
                title={i18n.t('settings-SettingsNavigation-broadcastsHover')}
                data-testid="broadcast-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/broadcasts') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-base-station-fill" />
                </Button>
              </NavLink>
            </IconWrap>
          )}

          {reportsPagePermission && (
            <IconWrap active={pathname.indexOf('/reports') === 0}>
              <NavLink
                to="/reports"
                title={i18n.t('settings-Reports-reports', {
                  defaultValue: 'Reports',
                })}
                data-testid="reports-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/reports') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-bar-chart-box-fill" />
                </Button>
              </NavLink>
            </IconWrap>
          )}

          <IconWrap active={pathname.indexOf('/search') === 0}>
            <NavLink
              to="/search"
              title={i18n.t('search-Search-search', { defaultValue: 'Search' })}
              data-testid="search-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/search') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-search-2-fill" />
              </Button>
            </NavLink>
          </IconWrap>

          <IconWrap active={pathname.indexOf('/settings') === 0}>
            <NavLink
              to="/settings/my-account"
              title={i18n.t('settings-SettingsNavigation-settings', {
                defaultValue: 'settings',
              })}
              data-testid="settings-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/settings') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-settings-3-fill" />
              </Button>
            </NavLink>
          </IconWrap>

          <IconWrap active={false}>
            <Button
              title={i18n.t('settings-SettingsNavigation-help', {
                defaultValue: 'Help',
              })}
              data-testid="help-icon"
              type="noStyle"
              size="zero"
              noOutline
              active={false}
              customStyle={iconButtonStyle}
            >
              <NoPrintIcon className="ri-question-line" />
            </Button>
          </IconWrap>
        </Wrapper>
      )}
    </SidebarWrap>
  );
};

Sidebar.propTypes = {
  announcementsPagePermission: PropTypes.bool,
  dashboardPagePermission: PropTypes.bool,
  feedbackPagePermission: PropTypes.bool,
  reportsPagePermission: PropTypes.bool,
  whitelabelLogo: PropTypes.string,
  taskPagePermission: PropTypes.bool,
};

Sidebar.defaultProps = {
  announcementsPagePermission: false,
  dashboardPagePermission: false,
  feedbackPagePermission: false,
  reportsPagePermission: false,
  taskPagePermission: false,
  whitelabelLogo: null,
};

export default Sidebar;
