import { SET_DONE_LOADING, SET_PRINT_LOADING_STATE } from '../actions/print.ts';

const DEFAULT_STATE = {
  printLoadingState: false,
  isDone: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PRINT_LOADING_STATE:
      return {
        ...state,
        printLoadingState: action.value,
      };
    case SET_DONE_LOADING:
      return {
        ...state,
        isDone: action.value,
      };
    default:
      return state;
  }
}
