import React, { useEffect, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate'; // eslint-disable-line import/no-named-default
import styled from 'styled-components';
import i18n from 'i18n-js';
import type {
  ActionMeta,
  MultiValue,
  RemoveValueActionMeta,
  InputActionMeta,
} from 'react-select';
import InputLabel from '../../inputCommonElements/InputLabel.ts';
import InputError from '../../inputCommonElements/InputError.tsx';
import Option, { centeredText } from '../shared/Option.tsx';
import DropdownIndicator from '../shared/DropdownIndicator';
import LABEL_THEMES from '../../../styles/themes/library/label';

import { Avatar, Chip, EmphasisText, MetaText } from '../..';
import type {
  AsyncPaginateLoadOptionsFunction,
  CreateDropdownComponentFunction,
  CreateMultiValueChipComponentFunction,
  CreateOptionComponentFunction,
  MultiOnChange,
  OptionType,
  RSOptionRef,
  SingleOrMultiValue,
  NoOptionsMessageComponentFunction,
} from '../shared/types.ts';
import { getLabelThemeColor } from '../../../styles/themes/library/utils/getLabelThemeColor.ts';
import { Container } from '../shared/styles.ts';

const OptionWrapper = styled.div`
  display: flex;
`;

const ChildrenWrapper = styled.div`
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChildrenColumnWrapper = styled.div`
  flex-direction: column;
  text-overflow: ellipsis;
  justify-content: center;
  padding-left: 10px;
`;

const RightSideOptionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 3;
`;

export interface AsyncSelectPaginateProps {
  customContainerStyle?: () => string;
  customInputStyle?: () => string;
  customLabelStyle?: () => string;
  customOptionStyle?: () => string;
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideBottomSpace?: boolean;
  isClearable: boolean;
  isMulti: boolean;
  label: string;
  loading?: boolean;
  loadOptions: AsyncPaginateLoadOptionsFunction;
  onChange: MultiOnChange;
  onClear?: (
    value?: OptionType[],
    actionMeta?: ActionMeta<OptionType> | RemoveValueActionMeta<OptionType>
  ) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  placeholder?: string;
  key: string;
  value: OptionType[];
}

const AsyncSelectPaginate = React.forwardRef(
  (
    {
      customContainerStyle,
      customInputStyle,
      customLabelStyle,
      customOptionStyle,
      dataTestId,
      disabled,
      error,
      helperText,
      hideBottomSpace,
      isClearable,
      isMulti,
      label,
      loadOptions,
      onChange,
      onInputChange,
      onClear = () => null,
      placeholder,
      key,
      value,
      ...otherProps
    }: AsyncSelectPaginateProps,
    ref
  ) => {
    const chipLabel = `${key}-selected-chip`;
    const selectClass = `${key}-AsyncSelectPaginate`;
    const [selected, setSelected] = useState<SingleOrMultiValue>(value);
    const [lastClicked, setLastClicked] = useState('');
    const selectRef = useRef<HTMLDivElement>(null);
    /**
     * Handles adding and removing items (chips) from the react-select menu and input textbar
     * @param val all selected data or single data item to remove
     * @param actionMeta react-select ActionMeta object
     */
    const handleChange = (
      newValue: SingleOrMultiValue,
      actionMeta: ActionMeta<OptionType> | RemoveValueActionMeta<OptionType>
    ) => {
      // const handleChange = (value, actionType) => {
      if (actionMeta?.action === 'clear') {
        onClear(value, actionMeta);
      }
      // Overriding react-select's chip means defining the remove functionality
      if (actionMeta.action.includes('remove')) {
        const multiSelect = selected as MultiValue<OptionType>;
        const newSelection = [...multiSelect];
        newSelection.splice(
          multiSelect.findIndex(
            (option) => option.value === actionMeta.option?.value
          ),
          1
        );
        setSelected(newSelection);
        onChange(newSelection, actionMeta);
        // Otherwise, utilize react-select's addition functionality
      } else {
        setSelected(newValue);
        onChange(newValue, actionMeta);
      }
    };

    const createDropdownIndicator: CreateDropdownComponentFunction = (
      props
    ) => <DropdownIndicator dataTestId={dataTestId} {...props} />;

    // If you want to insert right side content
    // pass a key value pair with "rightSideOptionContent"
    // into the options object for the "loadOptions" prop
    // see TemplateAsyncSelect for an example.
    const createOption: CreateOptionComponentFunction = (props) => {
      if (props?.data?.avatarContent) {
        const role = props?.data?.role ? props?.data?.role.toLowerCase() : null;
        return (
          <Option
            customOptionStyle={customOptionStyle}
            dataTestId={dataTestId}
            applyLabelColor={props?.data?.applyLabelColor}
            isCheckable={isMulti}
            {...props}
            innerRef={ref as RSOptionRef}
          >
            <OptionColumnWrapper>
              <Avatar size="md" avatarUrl={props?.data?.avatarContent} />
              <ChildrenColumnWrapper>
                <EmphasisText>{props?.data?.label}</EmphasisText>
                {role ?? <MetaText>{role}</MetaText>}
              </ChildrenColumnWrapper>
            </OptionColumnWrapper>
          </Option>
        );
      }
      if (props?.data?.rightSideOptionContent)
        return (
          <Option
            customOptionStyle={customOptionStyle}
            dataTestId={dataTestId}
            applyLabelColor={props?.data?.applyLabelColor}
            isCheckable={isMulti}
            {...props}
            innerRef={ref as RSOptionRef}
          >
            <OptionWrapper>
              <ChildrenWrapper>{props.children}</ChildrenWrapper>
              <RightSideOptionWrapper>
                {props?.data?.rightSideOptionContent}
              </RightSideOptionWrapper>
            </OptionWrapper>
          </Option>
        );
      return (
        <Option
          customOptionStyle={customOptionStyle}
          dataTestId={dataTestId}
          applyLabelColor={props?.data?.applyLabelColor}
          isCheckable={isMulti}
          {...props}
          innerRef={ref as RSOptionRef}
        >
          {props.data.label}
        </Option>
      );
    };

    /** Creates Selected Chip components in input textbar */
    const createMultiValueChip: CreateMultiValueChipComponentFunction = (
      props
    ) => {
      const { data } = props;
      return (
        <Chip
          {...props}
          dataTestId={chipLabel}
          onDelete={() =>
            handleChange(data, {
              action: 'remove-value',
              option: data,
              name: key,
            } as RemoveValueActionMeta<OptionType>)
          }
          backgroundColor={getLabelThemeColor(data.color || '')}
          textColor={
            data.color ? LABEL_THEMES.LABEL_TEXT : LABEL_THEMES.LABEL_DARK_TEXT
          }
        >
          {data.label}
        </Chip>
      );
    };

    const createNoOptionComponent: NoOptionsMessageComponentFunction = (
      props
    ) => (
      <Option
        dataTestId={dataTestId}
        {...props}
        innerRef={ref as RSOptionRef}
        isSelected={false}
        customOptionStyle={centeredText}
      >
        {i18n.t('customers-InfiniteScroll-noResultsFound')}
      </Option>
    );

    /** Overriding native click behavior */
    useEffect(() => {
      const handleClick = (ev: MouseEvent) => {
        if (selectRef?.current) {
          const target = (ev.target as Element).closest('div');
          // If user clicks on a selected chip, keep menu closed
          if (target?.dataset?.testid?.includes(chipLabel)) {
            selectRef.current?.focus();
            setLastClicked(`${target.dataset.testid} close`);
            // If user clicks on input...
          } else if (target?.className.includes(selectClass)) {
            // after clicking a chip, open menu
            if (lastClicked.includes(chipLabel)) {
              selectRef.current?.blur();
              selectRef.current?.focus();
              setLastClicked(`${target.className} open`);
              // after previously opening the menu, close the menu
            } else if (lastClicked.includes('open')) {
              selectRef.current?.blur();
              setLastClicked(`${target.className} close`);
              // after previously closing the menu, open the menu
            } else if (lastClicked.includes('close')) {
              selectRef.current?.focus();
              setLastClicked(`${target.className} open`);
            }
          } else {
            // reset last clicked
            setLastClicked('');
          }
        }
      };

      window.removeEventListener('click', handleClick);
      window.addEventListener('click', handleClick);
      return () => window.removeEventListener('click', handleClick);
    }, [chipLabel, lastClicked, selectClass]);

    useEffect(() => {
      setSelected(value);
    }, [value]);

    return (
      <Container
        error={error}
        disabled={disabled}
        prefix={selectClass}
        customContainerStyle={customContainerStyle}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
          >
            {label}
          </InputLabel>
        ) : null}
        <AsyncPaginate
          key={key}
          name={key}
          // @ts-expect-error overwrite ref
          ref={ref}
          loadOptions={loadOptions}
          value={value}
          onChange={handleChange}
          onInputChange={onInputChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={isClearable}
          menuPlacement="auto"
          classNamePrefix={selectClass}
          components={{
            DropdownIndicator: createDropdownIndicator,
            Option: createOption,
            MultiValueContainer: createMultiValueChip,
            NoOptionsMessage: createNoOptionComponent,
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={!isMulti}
          defaultOptions
          cacheOptions
          {...otherProps}
        />
        {(!hideBottomSpace || error) && (
          <InputError error={error} helperText={helperText} />
        )}
      </Container>
    );
  }
);

AsyncSelectPaginate.displayName = 'AsyncSelectPaginate';

export default AsyncSelectPaginate;
