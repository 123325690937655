import React from 'react';
import i18n from 'i18n-js';
import {
  TableHead,
  Checkbox,
  Button,
  Avatar,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements/index';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  ContactDataWrapper,
  AvatarWrapper,
  StyledTableRow,
  StyledCheckboxTD,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { addSelectedDataTestId, getSortOptions } from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll.js';
import THEMES from '../../../../styles/themes/app.js';
import ChipLabels from '../../../ChipLabels/ChipLabels.js';
import type { Label } from '../../../../utils/helpers/types';

interface LabelsTableProps {
  labels: Array<Label>;
  selectedAudience?: {
    labelIds: string[];
    remove?: boolean;
  };
  loading: boolean;
  loadMoreRows: () => void;
  onAdd: (args: { labelIds: string[]; remove?: boolean }) => void;
  onSort: (args: { label: string; value: string }) => void;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount?: number;
  };
  sort: {
    label: string;
    value: string;
  };
  heightDifference?: number;
  disableCheckboxes?: boolean;
  type?: string;
}

const LabelsTable = ({
  labels,
  selectedAudience = {
    labelIds: [],
  },
  loading,
  loadMoreRows,
  onAdd,
  onSort,
  pageInfo,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
  type = '',
}: LabelsTableProps) => {
  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, shouldCheckItem } =
    useMultiSelectTableItems({
      items: labels,
    });

  const shouldItemBeChecked = (id: string) => {
    if (type === 'TASK') {
      return selectedAudience?.labelIds?.includes(id);
    }
    return shouldCheckItem(id);
  };

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ labelIds: selectedItems });
    handleSelectNone();
  };

  const onCheckItem = (id: string) => {
    if (type === 'TASK') {
      const isSelected = selectedAudience.labelIds.includes(id);
      onAdd({
        labelIds: [id],
        remove: isSelected,
      });
    }
    handleCheckItem(id);
  };

  const handleKeyDown = (e: React.KeyboardEvent, id: string) => {
    if (e.key === 'Enter') {
      onCheckItem(id);
    }
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitSort={onSort}
        />
        {type !== 'TASK' ? (
          <Button
            dataTestId={`labels-table-${addSelectedDataTestId}`}
            onClick={() => handleAdd()}
            disabled={selectedItems.length === 0 || disableCheckboxes}
          >
            {i18n.t('broadcasts-broadcast-addSelected', {
              defaultValue: 'Add %{number} Selected',
              number: selectedItems.length,
            })}
          </Button>
        ) : null}
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <th>
              {i18n.t('broadcasts-broadcastList-labelName', {
                defaultValue: 'Label Name',
              })}
            </th>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={labels}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults')}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: { list: Array<Label>; index: number }) => {
          const { list, index } = args;
          const label = list[index] as Label;
          return (
            <StyledTableRow
              key={label.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disableCheckboxes) return;
                onCheckItem(label.id);
              }}
              onKeyDown={(e) => {
                if (disableCheckboxes) return;
                handleKeyDown(e, label.id);
              }}
            >
              <StyledCheckboxTD>
                <Checkbox
                  checked={shouldItemBeChecked(label.id)}
                  dataTestId="create-broadcastList-checkboxGroup"
                  onCheck={() => {
                    /* Not needed - entire table row is clickable */
                  }}
                  disabled={disableCheckboxes}
                />
                <AvatarWrapper>
                  <Avatar size="md" icon="label" disabled={disableCheckboxes} />
                  <ContactDataWrapper>
                    <ChipLabels
                      options={{
                        value: [
                          { ...label, text: label.name, value: label.id },
                        ],
                      }}
                      size="sm"
                    />
                  </ContactDataWrapper>
                </AvatarWrapper>
              </StyledCheckboxTD>
            </StyledTableRow>
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

export default LabelsTable;
