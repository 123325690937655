import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { GROUPS_V2_QUERY, usePaginated } from 'client-lib';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CreateBroadcastListTemplate from '../CreateBroadcastListTemplate';
import { TextInput, TextArea, Loading, InputLabel } from '../../../../elements';
import GroupAsyncSelect from '../../../AsyncSelects/GroupAsyncSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  max-width: 550px;
  position: relative;
`;

const AreaContainer = styled(Grid)`
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.noAnnouncementChannel ? '20px' : '0')};
`;

const CreateBroadcastListPage = ({
  wizardGlobalProps,
  closeWizard,
  setWizardPage,
}) => {
  const client = useApolloClient();
  const {
    title,
    tag,
    tagId,
    handleInputTagChange,
    handleEditTag,
    handleInputListDescriptionChange,
    handleCreateTag,
    setTagId,
    loading,
    setListError,
    isEdit,
  } = wizardGlobalProps;
  const { name, error, description, descriptionError } = tag;

  const accountId = useSelector(
    (state) => state?.session?.currentUser?.accountId
  );

  const { groupsV2: groups } = usePaginated({
    client,
    query: GROUPS_V2_QUERY,
    queryVariables: { accountId, broadcastEnabled: true },
    key: 'groupsV2',
    policy: 'network-only',
    nextPolicy: 'cache-and-network',
  });

  const nextStep = async () => {
    if (!tagId) {
      const result = await handleCreateTag(
        name,
        description,
        wizardGlobalProps.tag.groups,
        setListError,
        () => {},
        () => {}
      );
      if (result?.createTag?.tag) {
        setTagId(result.createTag.tag.id);
        setWizardPage('NextPage');
      }
    } else {
      const result = await handleEditTag(name, description);
      if (result?.updateTag?.tag) {
        setWizardPage('NextPage');
      }
    }
  };

  const btmEnabledAtGroupLevel = (groups?.length ?? 0) > 0;

  return (
    <CreateBroadcastListTemplate
      title={title}
      continueButtonText={i18n.t(
        'slideouts-GroupMessageRecipients-saveContinue'
      )}
      continueButtonAction={() => nextStep()}
      continueButtonDisabled={
        !name ||
        (btmEnabledAtGroupLevel && !wizardGlobalProps.tag.groups) ||
        (btmEnabledAtGroupLevel && wizardGlobalProps.tag.groups.length === 0) ||
        error ||
        descriptionError ||
        (btmEnabledAtGroupLevel && wizardGlobalProps.tag.groupsError)
      }
      backButtonAction={() => {
        closeWizard();
      }}
      showIcon={isEdit}
      isEdit={false}
    >
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <InputContainer>
            <TextInput
              type="text"
              value={name}
              label={<>{i18n.t('settings-AddOrEditList-listName')}</>}
              error={error}
              onChange={handleInputTagChange}
              autoFocus
              isRequired
              placeholder={i18n.t('broadcasts-createList-enterName', {
                defaultValue: 'Enter list name',
              })}
              dataTestId="create-broadcast-listName"
            />
          </InputContainer>
          {btmEnabledAtGroupLevel ? (
            <GroupAsyncSelect
              value={wizardGlobalProps.tag.groups || []}
              setValue={wizardGlobalProps.setListGroups}
              label={
                <InputLabel isRequired>
                  {i18n.t('settings-TeamMemberForm-groups')}
                </InputLabel>
              }
              placeholder={i18n.t(
                'settings-GroupDropDown-groupDropDownPlaceholder'
              )}
              permissionFilter="VIEW_GROUP"
              isMulti
              omitAll
              areBroadcastEnabled
              error={wizardGlobalProps.tag.groupsError}
            />
          ) : null}
          <AreaContainer>
            <TextArea
              value={description}
              onChange={handleInputListDescriptionChange}
              rows={5}
              error={descriptionError}
              type="text"
              label={i18n.t('slideouts-GroupMessageOverview-description')}
              dataTestId="create-broadcast-listDescription"
              placeholder={i18n.t('broadcasts-createList-enterDescription', {
                defaultValue: 'Enter description',
              })}
            />
          </AreaContainer>
        </Container>
      )}
    </CreateBroadcastListTemplate>
  );
};

CreateBroadcastListPage.propTypes = {
  wizardGlobalProps: PropTypes.shape({
    handleCreateTag: PropTypes.func,
    handleInputTagChange: PropTypes.func,
    handleEditTag: PropTypes.func,
    handleOnSaveSelectedAudience: PropTypes.func,
    search: PropTypes.string,
    setListGroups: PropTypes.func,
    setSearch: PropTypes.func,
    handleInputListDescriptionChange: PropTypes.func,
    setTagId: PropTypes.func,
    setListError: PropTypes.func,
    tag: PropTypes.shape({
      groups: PropTypes.array,
      groupsError: PropTypes.string,
      name: PropTypes.string,
      error: PropTypes.string,
      description: PropTypes.string,
      descriptionError: PropTypes.string,
    }),
    tagId: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    isEdit: PropTypes.bool,
  }).isRequired,
  setWizardPage: PropTypes.func.isRequired,
  closeWizard: PropTypes.func.isRequired,
};

export default CreateBroadcastListPage;
