import React, { useMemo, useRef } from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { useApolloClient } from '@apollo/client';
import { useGroupsQuery } from 'client-lib';
import { formatChannelLabel } from 'client-lib/src/lib/utils/helpers';
import {
  TextInput,
  InputLabel,
  InputError,
  Text,
  Checkbox,
} from '../../../../../elements';
import AsyncSelect from '../../../../../elements/Select/AsyncSelect/AsyncSelect.tsx';
import Tooltip from '../../../../../elements/Tooltip/Tooltip';
import THEMES from '../../../../../styles/themes/app';

const NameChannelContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

const ChannelSelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const InfoWrapper = styled(Grid)`
  margin-left: 8px;
  font-size: 20px;
  color: ${THEMES.FOREGROUND_LOW};
  display: flex;
  align-items: flex-end;
`;

const RouteToGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 8px;
`;

const CheckBoxWrapper = styled.div`
  padding-right: 8px;
  margin-top: 5px;
`;

const InfoIconWrapper = styled.div`
  padding-top: 5px;
`;

const GroupMessageOverviewName = ({
  error,
  messageName,
  setMessageName,
  messageError,
  outboundChannelId,
  pageError,
  setOutboundChannelId,
  wizardGlobalProps,
  disabled,
  routeToGroup,
  routeToGroupId,
  setRouteToGroup,
  handleRouteToGroup,
}) => {
  const { ff_broadcast_text_reply_routing } = wizardGlobalProps?.accountData
    ?.account ?? { ff_broadcast_text_reply_routing: false };
  const nameInput = useRef();
  const client = useApolloClient();
  const { data: groupsQueryData } = useGroupsQuery({ client });

  const selectOptions = useMemo(() => {
    if (!groupsQueryData?.groups) return [];
    return groupsQueryData?.groups?.map((group) => ({
      value: group.id,
      label: group.name,
    }));
  }, [groupsQueryData?.groups]);

  const selectedRouteToGroup = useMemo(() => {
    return selectOptions?.find((option) => option.value === routeToGroupId);
  }, [routeToGroupId, selectOptions]);

  const channelSelect = () => {
    return (
      <ChannelSelectContainer>
        <InputLabel
          error={error}
          dataTestId="group-message-channel-select-label"
        >
          {i18n.t('broadcasts-BroadcastList-sender')}
        </InputLabel>
        <AsyncSelect
          key="group-message-channel-select"
          defaultOptions={wizardGlobalProps?.channelsData?.channels.map(
            (channel) => ({
              value: channel.id,
              label: formatChannelLabel(channel),
            })
          )}
          loadOptions={() =>
            wizardGlobalProps?.channelsData?.channels.map((channel) => ({
              value: channel.id,
              label: formatChannelLabel(channel),
            }))
          }
          value={wizardGlobalProps?.channelsData?.channels
            .filter((c) => c.id === outboundChannelId)
            .map((c) => ({
              value: c.id,
              label: formatChannelLabel(c),
            }))
            .pop()}
          onChange={(e) => setOutboundChannelId(e.value)}
          error={pageError}
          dataTestId="group-message-channel-select"
          disabled={disabled}
          hideBottomSpace
        />
        <InputError
          error={error}
          helperText={i18n.t('slideouts-GroupMessageOverview-sendingNumber')}
          dataTestId="group-message-channel-select-helper"
        />
      </ChannelSelectContainer>
    );
  };

  return (
    <NameChannelContainer>
      {channelSelect()}
      <TextInput
        label={i18n.t('slideouts-GroupMessageName-messageName')}
        type="text"
        value={messageName}
        disabled={!outboundChannelId || disabled}
        onChange={(e) => setMessageName(e.target.value)}
        placeholder={i18n.t('slideouts-GroupMessageOverview-specialPromotion')}
        error={messageError}
        ref={nameInput}
        dataTestId="group-message-name-input"
        helperText={i18n.t('slideouts-GroupMessageOverview-descriptiveName')}
      />
      {!!ff_broadcast_text_reply_routing && (
        <RouteToGroupWrapper>
          <CheckBoxWrapper>
            <Checkbox
              checked={routeToGroup}
              onCheck={setRouteToGroup}
              disabled={disabled}
            />
          </CheckBoxWrapper>
          <Text>{i18n.t('slideouts-GroupMessageOverview-routeToGroup')}</Text>
          <InfoIconWrapper>
            <Tooltip
              id="routeToGroupTooltip"
              place="top"
              elementsContent={
                <Text>
                  {i18n.t('slideouts-GroupMessageOverview-routeToGroupInfo')}
                </Text>
              }
              customStyle={() => 'max-width: 500px; padding: 4px;'}
              disabled={false}
            >
              <InfoWrapper>
                <i
                  data-tip
                  data-for="routeToGroupTooltip"
                  className="ri-information-line"
                />
              </InfoWrapper>
            </Tooltip>
          </InfoIconWrapper>
        </RouteToGroupWrapper>
      )}
      <div>
        {routeToGroup && groupsQueryData?.groups?.length > 0 && (
          <AsyncSelect
            key="group-message-route-to-group-select"
            defaultOptions={selectOptions}
            loadOptions={async (input) => {
              if (!input) return selectOptions;
              return selectOptions?.filter(
                (option) =>
                  option.label.toLowerCase().includes(input.toLowerCase()) ||
                  option.value === routeToGroupId
              );
            }}
            value={selectedRouteToGroup}
            onChange={(e) => handleRouteToGroup(e.value)}
            error={pageError}
            dataTestId="group-message-route-to-group-select"
            disabled={disabled}
            hideBottomSpace
          />
        )}
      </div>
    </NameChannelContainer>
  );
};

GroupMessageOverviewName.propTypes = {
  error: PropTypes.string,
  messageName: PropTypes.string,
  setMessageName: PropTypes.func.isRequired,
  messageError: PropTypes.string,
  outboundChannelId: PropTypes.string,
  pageError: PropTypes.string,
  setOutboundChannelId: PropTypes.func.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  routeToGroup: PropTypes.bool,
  routeToGroupId: PropTypes.string,
  handleRouteToGroup: PropTypes.func,
  setRouteToGroup: PropTypes.func,
};

GroupMessageOverviewName.defaultProps = {
  error: '',
  messageName: '',
  messageError: '',
  outboundChannelId: '',
  pageError: '',
  disabled: false,
  routeToGroup: false,
  handleRouteToGroup: () => {},
  setRouteToGroup: () => {},
  routeToGroupId: '',
};

export default GroupMessageOverviewName;
