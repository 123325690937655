import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../../../elements';
import ExitConfirmationModal from '../../../shared/AudienceBuilderTables/subComponents/ExitConfirmationModal';
import { addSelectedDataTestId } from '../../../shared/utils';

interface SaveAndExitProps {
  closeWizard: (status: string) => void;
  onClose?: (() => void) | null;
  sendStartedAt?: boolean;
}

const SaveAndExit = ({
  closeWizard,
  onClose = null,
  sendStartedAt = false,
}: SaveAndExitProps) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  const [isSaveAndExitModalOpen, setIsSaveAndExitModalOpen] = useState(false);

  const handleSave = useCallback(() => {
    if (onClose) onClose();
    closeWizard('saved');
  }, [closeWizard, onClose]);

  const handleContinueCheck = () => {
    const el = document.querySelector(
      `[data-testid*='${addSelectedDataTestId}']`
    );
    const currentSelection = el?.textContent?.split(' ')[1];
    if (currentSelection && +currentSelection > 0) {
      setIsSaveAndExitModalOpen(true);
    } else {
      handleSave();
    }
  };

  useEffect(() => {
    if (location?.pathname && location?.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
      handleSave();
    }
  }, [currentLocation, handleSave, location.pathname]);

  return (
    <>
      <ExitConfirmationModal
        dataTestId="btm"
        isOpen={isSaveAndExitModalOpen}
        onCancel={() => setIsSaveAndExitModalOpen(false)}
        onSave={handleSave}
      />
      <Button onClick={handleContinueCheck}>
        {sendStartedAt
          ? i18n.t('slideouts-GroupMessageRecipients-exitReview')
          : i18n.t('slideouts-GroupMessageRecipients-saveExit')}
      </Button>
    </>
  );
};

export default SaveAndExit;
