import React, { useState, useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  PK_TYPENAMES,
  SEARCH_FAX_CONTACTS_QUERY,
  useCreateFaxThread,
  CHANNELS_QUERY,
  useIsFaxAvailable,
  hasFaxBlocked,
} from 'client-lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import i18n from 'i18n-js';
import {
  fileNameWithoutExtension,
  resolveAvatarUrl,
  formatPhoneNumber,
  contactName,
} from 'client-lib/src/lib/utils/helpers';
import {
  showUploadProgressBar,
  hideUploadProgressBar,
  showUploadFailureBar,
  setDefaultContact,
} from '../../actions/uploadModal';
import { phoneRegex, normalizePhone } from '../Inputs/validation';
import { closeCreateSectionModal, openSnackbar } from '../../actions/general';
import {
  setCreateFaxThread,
  clearCreateSection,
} from '../../actions/createSection';
import FileReaderInput from '../Inputs/FileReaderInput';
import UploadArt from '../Modals/UploadArt';
import {
  Heading2,
  IconLabel,
  Button,
  Select,
  InfiniteSuggest,
  InputError,
  Avatar,
} from '../../elements';
import SelectedEntity from '../../elements/EntityCard/SelectedEntity';
import AddFaxNumber from '../Modals/AddFaxNumber';
import CreateMultiTag from '../Tag/CreateMultiTag';
import { CreateFormAttachment } from '../FileUpload/CreateFormAttachment';
import validateAttachment from '../../utils/helpers/validateAttachment.ts';
import {
  I18nError,
  MAX_FILE_SIZE,
} from '../../utils/helpers/constants/index.ts';
import handleFileReaderInputChange from '../../utils/helpers/handleFileReaderInputChange.ts';

const CreateMultiTagWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  height: 90%;
  max-height: 90%;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
`;

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
  column-gap: 12px;
`;

const ButtonRow = styled.div`
  margin: 8px 0;
  display: flex;
`;

const AttachmentButtonWrap = styled.div`
  margin: 0 0 0 8px;
`;

const labelStyle = () => `
  margin-top: 8px;
  margin-left: 8px;
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

const AttachmentTypes = ['.pdf', 'application/pdf'];

const CreateFax = ({ createSection }) => {
  const client = useApolloClient();
  const history = useHistory();

  const [inputError, setInputError] = useState('');

  const [contactText, setContactText] = useState('');

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const [addActiveFaxNumberModal, setAddActiveFaxNumberModal] = useState(false);

  const dispatch = useDispatch();
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const uploadModal = useSelector((state) => state?.uploadModal);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const presenceTrackingAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_presence_tracking
  );

  const { faxFeatureAvailable } = useIsFaxAvailable({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const onMutationStart = () => {
    dispatch(showUploadProgressBar());
    dispatch(closeCreateSectionModal());
  };

  const onMutationSuccess = (data) => {
    const startedThread = data.startFaxThread.thread;

    history.push(`/threads/closed/${startedThread.id}`);

    dispatch(clearCreateSection());
    dispatch(hideUploadProgressBar());
  };

  const onMutationError = (err) => {
    setAttachment(null);
    dispatch(showUploadFailureBar());
    setTimeout(() => dispatch(hideUploadProgressBar()), 3000);
    console.error(err);
  };

  const handleNotifyError = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const {
    handleMutationStartThread,
    contact,
    setContact,
    contactFaxNumber,
    setContactFaxNumber,
    senderGroup,
    setSenderGroup,
    groupOptions,
    attachment,
    setAttachment,
    tags,
    setTags,
  } = useCreateFaxThread({
    client,
    threadsActiveGroupIds,
    onMutationStart,
    onMutationSuccess,
    onMutationError,
    initialThread: createSection.createFaxThread,
    onError: handleNotifyError,
    allGroups,
    channelsData,
  });

  useEffect(() => {
    if (uploadModal.defaultContact) {
      setContact(uploadModal.defaultContact);
      setContactFaxNumber(uploadModal.defaultContact.phoneNumber);
    }

    return () => dispatch(setDefaultContact(null));
  }, []);

  const showFromField = threadsActiveGroupIds?.length > 1;

  const onSuggestionSelected = (e, { suggestion }) => {
    // First set state based on selection, then move focus to messageInput.
    if (suggestion?.faxNumber) {
      setAddActiveFaxNumberModal(false);
      setContactFaxNumber(suggestion.faxNumber);
      setContact(suggestion);
      setInputError('');
    } else {
      setContactFaxNumber('');
      setContact(suggestion);
      setAddActiveFaxNumberModal(true);
    }
  };

  const onChange = (e) => setContactText(e.target.value);

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();

    if (phoneRegex.test(trimmedValue) === true) {
      const number = normalizePhone(trimmedValue);
      setContactFaxNumber(number);
      setContactText(number);
    } else if (!contact || contactFaxNumber === '') {
      setContactText('');
    }
  };

  const handleSetAttachment = (
    originalFile,
    originalFilename = originalFile.name
  ) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachment(attachment);
    };
    reader.readAsDataURL(originalFile);
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  useEffect(() => {
    const createFaxThreadRedux = {
      contact,
      contactFaxNumber,
      attachment,
      senderGroup,
    };
    dispatch(setCreateFaxThread(createFaxThreadRedux));
  }, [contact?.id, contactFaxNumber, attachment?.id, senderGroup?.value]);

  useEffect(() => {
    const suggestion = uploadModal?.defaultContact;
    if (suggestion?.faxNumber) {
      setContactFaxNumber(suggestion.faxNumber);
      setContact(suggestion);
      setInputError('');
    } else {
      setContactFaxNumber('');
      setContact(suggestion);
      setAddActiveFaxNumberModal(true);
    }
  }, []);

  const clearToFieldBubble = () => {
    setContactText('');
    setContactFaxNumber('');
    setContact(null);
  };

  const onFileError = (error) => {
    if (error instanceof I18nError) {
      dispatch(
        openSnackbar(i18n.t(error.i18n_id, error.i18n_variables), 'error')
      );
    } else {
      console.error(error);
    }
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(
      acceptedFiles[0],
      onFileError,
      handleSetAttachment,
      [],
      MAX_FILE_SIZE
    );
  };

  const renderAttachmentSection = () =>
    attachment !== null ? (
      <FullFormRow>
        <CreateMultiTagWrapper>
          <CreateMultiTag tags={tags} setTags={setTags} />
        </CreateMultiTagWrapper>

        <CreateFormAttachment
          attachments={[attachment]}
          onClose={() => setAttachment(null)}
        />
      </FullFormRow>
    ) : null;

  const inferAvatarChildren = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName || contact?.lastName
        ? contactName(contact)
        : undefined;
    }
    return undefined;
  };

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName || contact?.lastName
        ? contactName(contact)
        : contactFaxNumber;
    }
    if (!contact && contactFaxNumber) {
      return contactFaxNumber;
    }

    return '';
  };

  const inferEntityCardSubText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.account?.name || '';
    }
    return '';
  };

  const handleOnModalError = () => {
    clearToFieldBubble();
    setAddActiveFaxNumberModal(false);
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CreateFax-errorAddFax', {
          defaultValue: 'There was an error adding a fax line',
        }),
        'error'
      )
    );
  };

  const isContact = contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT;

  const avatarUrl = resolveAvatarUrl(contact);

  const sharedValidation =
    (isContact ? contactFaxNumber : contact?.id || contactFaxNumber) &&
    (showFromField ? senderGroup?.value : true);
  const enableSubmissionWithAttachment =
    sharedValidation &&
    attachment &&
    fileNameWithoutExtension(attachment.originalFilename);

  return (
    <Dropzone
      disableClick
      multiple={false}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      style={modalBodyStyleWithDragging}
    >
      <Container>
        <InnerContainer centerVertically={isDraggingOnPage}>
          {isDraggingOnPage ? (
            <UploadArt height="60%" />
          ) : (
            <>
              <HeadingWrap>
                <Avatar size="lg" type="external" icon="faxChannel" />
                <Heading2 dataTestId="new-message-header">
                  {i18n.t('slideouts-CreateFax-newFax', {
                    defaultValue: 'New Fax',
                  })}
                </Heading2>
              </HeadingWrap>
              {!faxFeatureAvailable && (
                <InputError
                  error={i18n.t('slideouts-CreateMessage-notEnabled', {
                    defaultValue: 'This channel is not enabled for this group',
                  })}
                />
              )}
              <Select
                key="createsection-createFax-senderGroup"
                label={i18n.t('slideouts-CreateThread-fromLabel')}
                options={groupOptions}
                value={senderGroup}
                onChange={(option) => setSenderGroup(option)}
                disabled={!faxFeatureAvailable || !showFromField}
              />

              {!contactFaxNumber && !contact?.id ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-toLabel'),
                    error: inputError,
                    onBlur: certifyToInputValueOnBlur,
                    value: contactText,
                    onChange,
                    autoFocus: true,
                    disabled: !faxFeatureAvailable,
                    placeholder: i18n.t(
                      'slideouts-CreateThread-enterNameOrNumber'
                    ),
                  }}
                  query={SEARCH_FAX_CONTACTS_QUERY}
                  queryKey="searchFaxContacts"
                  queryVariables={{ query: contactText, after: null }}
                  onSelect={onSuggestionSelected}
                  includePresence={presenceTrackingAvailable}
                  includeGroupIndicator
                  isDisabled={(node) => node?.faxOptOut || hasFaxBlocked(node)}
                  rightAlignedText={(node) => {
                    if (hasFaxBlocked(node)) {
                      return i18n.t('threads-CreateThread-blocked', {
                        defaultValue: 'Blocked',
                      });
                    }
                    if (node?.faxOptOut) {
                      return i18n.t(
                        'slideouts-GroupMessageRecipients-optedOut'
                      );
                    }
                    if (!node?.faxNumber) {
                      return i18n.t('modals-AddFaxNumber-addFax', {
                        defaultValue: 'Add Fax Line',
                      });
                    }
                    return formatPhoneNumber(node.faxNumber);
                  }}
                />
              ) : (
                <SelectedEntity
                  label={i18n.t('slideouts-CreateThread-toLabel')}
                  avatarChildren={inferAvatarChildren(contact)}
                  maintext={inferEntityCardMainText(contact)}
                  subtext={inferEntityCardSubText(contact)}
                  onDelete={clearToFieldBubble}
                  avatarProps={{
                    avatarUrl,
                    type: 'external',
                    displayPresence: presenceTrackingAvailable,
                    active: contact?.online,
                  }}
                />
              )}
              {renderAttachmentSection()}
              <ButtonRow>
                <Button
                  disabled={!enableSubmissionWithAttachment}
                  onClick={handleMutationStartThread}
                  data-testid="send-message-button"
                >
                  {i18n.t('slideouts-CreateThread-send')}
                </Button>
                <AttachmentButtonWrap>
                  <FileReaderInput
                    name="modalAttachmentInput"
                    WrapperComponent={FileReaderInputWrapper}
                    handleFileReaderInputChange={(e) =>
                      handleFileReaderInputChange(
                        e,
                        onFileError,
                        handleSetAttachment,
                        [],
                        MAX_FILE_SIZE
                      )
                    }
                    accept={AttachmentTypes.join(',')}
                    disabled={!faxFeatureAvailable}
                  >
                    <IconLabel
                      htmlFor="modalAttachmentInput"
                      customStyle={labelStyle}
                      contrast="medColor"
                      disabled={!faxFeatureAvailable}
                    >
                      <i className="ri-attachment-2" />
                    </IconLabel>
                  </FileReaderInput>
                </AttachmentButtonWrap>
              </ButtonRow>
            </>
          )}
        </InnerContainer>
      </Container>
      {contact && (
        <AddFaxNumber
          open={addActiveFaxNumberModal}
          onClose={() => {
            clearToFieldBubble();
            setAddActiveFaxNumberModal(false);
          }}
          onSave={(faxNumber) => {
            setContactFaxNumber(faxNumber);
            setAddActiveFaxNumberModal(false);
            dispatch(
              openSnackbar(
                i18n.t('slideouts-CreateFax-faxLineAdded', {
                  contactName: contactName(contact),
                  defaultValue: `Fax line for ${contactName(
                    contact
                  )} has been added.`,
                })
              )
            );
          }}
          onError={handleOnModalError}
          contact={contact}
        />
      )}
    </Dropzone>
  );
};

CreateFax.propTypes = {
  createSection: PropTypes.object.isRequired,
};

export default CreateFax;
