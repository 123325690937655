import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PK_TYPENAMES } from 'client-lib';
import styled from 'styled-components';
import UserSuggestion from './TypeSuggestions/UserSuggestion';
import GroupSuggestion from './TypeSuggestions/GroupSuggestion';
import ContactSuggestion from './TypeSuggestions/ContactSuggestion';
import CompanySuggestion from './TypeSuggestions/CompanySuggestion';
import WorkOrderSuggestion from './TypeSuggestions/WorkOrderSuggestion';
import LabelSuggestion from './TypeSuggestions/LabelSuggestion';

export const INFINITE_SUGGEST_ROW_HEIGHT = 57;

const SuggestionWrapper = styled.div`
  height: ${INFINITE_SUGGEST_ROW_HEIGHT}px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.rightAlignedText ? '2fr 1fr' : '1fr'};
`;

export const entityCardSuggestionCustomStyles = () => `
  padding: 8px;
  min-height: 40px;
  cursor: default;
`;

const UniversalSuggestion = ({
  suggestion,
  onSelect,
  includePresence,
  includeGroupIndicator,
  isFocused,
  disabled,
  rightAlignedText,
  displayAddPhoneNumberButton,
}) => {
  let typenameSuggestion = null;

  const [isHovering, setIsHovering] = useState(false);

  const selectSuggestion = (e) => onSelect(e, { suggestion });

  if (suggestion.__typename === PK_TYPENAMES.USER) {
    typenameSuggestion = (
      <UserSuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
        includePresence={includePresence}
      />
    );
  } else if (suggestion.__typename === PK_TYPENAMES.GROUP) {
    typenameSuggestion = (
      <GroupSuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
        includeGroupIndicator={includeGroupIndicator}
      />
    );
  } else if (suggestion.__typename === PK_TYPENAMES.LABEL) {
    typenameSuggestion = (
      <LabelSuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
      />
    );
  } else if (suggestion.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
    typenameSuggestion = (
      <ContactSuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
        disabled={disabled}
        rightAlignedText={rightAlignedText}
        displayAddPhoneNumberButton={displayAddPhoneNumberButton}
      />
    );
  } else if (suggestion.__typename === PK_TYPENAMES.CUSTOMER_ACCOUNT) {
    typenameSuggestion = (
      <CompanySuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
      />
    );
  } else if (suggestion.__typename === PK_TYPENAMES.WORK_ORDER) {
    typenameSuggestion = (
      <WorkOrderSuggestion
        suggestion={suggestion}
        isHighlighted={isHovering || isFocused}
      />
    );
  }

  return (
    <SuggestionWrapper
      onClick={(...props) => {
        if (!disabled) selectSuggestion(...props);
      }}
      // placeholders for keydown and keyup events
      onKeyDown={() => {}}
      onKeyUp={() => {}}
      role="menuitem"
      tabIndex="0"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disabled={disabled}
      rightAlignedText={rightAlignedText}
    >
      {typenameSuggestion}
    </SuggestionWrapper>
  );
};

UniversalSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  includePresence: PropTypes.bool,
  includeGroupIndicator: PropTypes.bool,
  isFocused: PropTypes.bool,
  disabled: PropTypes.bool,
  rightAlignedText: PropTypes.string,
  displayAddPhoneNumberButton: PropTypes.bool,
};

UniversalSuggestion.defaultProps = {
  onSelect: () => {},
  includePresence: false,
  includeGroupIndicator: false,
  isFocused: false,
  disabled: '',
  rightAlignedText: '',
  displayAddPhoneNumberButton: true,
};

export default UniversalSuggestion;
