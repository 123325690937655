import React, { useState, useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  PK_TYPENAMES,
  SEARCH_CUSTOMERS_QUERY,
  useCreateEmail,
  useEmailSignature,
  useIsEmailAvailable,
  hasEmailBlocked,
  useTemplateQuery,
  usePostSendCompleteTask,
} from 'client-lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import i18n from 'i18n-js';
import {
  resolveAvatarUrl,
  contactName,
} from 'client-lib/src/lib/utils/helpers';
import { emailRegex } from 'client-lib/src/lib/utils/validation';
import autosize from 'autosize';
import {
  showUploadProgressBar,
  hideUploadProgressBar,
  showUploadFailureBar,
  setDefaultContact,
} from '../../actions/uploadModal';
import {
  closeCreateSectionModal,
  openSnackbar,
  setActiveSidebar,
} from '../../actions/general';
import {
  clearCreateSection,
  setCreateEmail,
} from '../../actions/createSection';
import UploadArt from '../Modals/UploadArt';
import {
  Heading2,
  Button,
  TextArea,
  Select,
  SelectedEntity,
  InfiniteSuggest,
  TextInput,
  Text,
  Chip,
  InputError,
  Avatar,
} from '../../elements';
import AddEmailAddress from '../Modals/AddEmailAddress';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import CreateMultiTag from '../Tag/CreateMultiTag';
import AnnotationsModal from '../Annotations/AnnotationsModal';
import { CreateFormAttachment } from '../FileUpload/CreateFormAttachment';
import CreateSectionButtons from './CreateSectionButtons.tsx';
import RichTextEditor from '../../elements/RichTextEditor/RichTextEditor.tsx';
import convertLexicalToHtmlAndText from '../../elements/RichTextEditor/utils/convertLexicalToHtmlAndText.ts';
import ChangeFactory, {
  MutationMethod,
} from '../../elements/RichTextEditor/utils/onChangeFactory.ts';
import appendEmailSignature from '../../elements/RichTextEditor/utils/appendEmailSignature.ts';
import lexicalDeserialization from '../../elements/RichTextEditor/utils/lexicalDeserialization.ts';
import { InsertType } from '../../elements/RichTextEditor/utils/constants.ts';
import handleFileReaderInputChange from '../../utils/helpers/handleFileReaderInputChange.ts';
import validateAttachment from '../../utils/helpers/validateAttachment.ts';
import { I18nError } from '../../utils/helpers/constants/index.ts';
import useUploadLexicalImagesToS3 from '../../elements/RichTextEditor/utils/useUploadLexicalImagesToS3.tsx';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  min-height: 90%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
  margin-bottom: 12px;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
  column-gap: 12px;
`;

const CarbonCopyChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

const ChipContainer = styled.div`
  margin: 8px 8px 0 0;
`;

const ExpandableWrapper = styled.div`
  ${(props) =>
    props.isExpanded &&
    `
      display: flex;
      flex-direction: column;
      position: absolute;
      width: calc(100vw - 108px);
      height: calc(100vh - 46px);
      margin: 16px;
      bottom: 16px;
      right: 0;
      z-index: 100;
    `}
`;

const addCcCustomStyle = (props) => `
 color: ${BUTTON_THEMES.LINK_TEXT(props)};
 :hover {
  color: ${BUTTON_THEMES.LINK_TEXT_HOVER(props)};
 }
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

const CreateEmail = ({ createSection }) => {
  const client = useApolloClient();

  const history = useHistory();
  const dispatch = useDispatch();

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const uploadModal = useSelector((state) => state?.uploadModal);
  const activeEmailChannels = useSelector(
    (state) => state?.accountData?.activeEmailChannels
  );
  const ff_rich_text_email_formatting = useSelector(
    (state) => state?.accountData?.account?.ff_rich_text_email_formatting
  );
  const accountName = useSelector((state) => state?.accountData?.account?.name);

  const taskId = createSection?.createEmail?.taskId;
  const templateId = createSection?.createEmail?.templateId;

  const [annotate, setAnnotate] = useState(false);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [contactText, setContactText] = useState('');

  const [carbonCopyContactText, setCarbonCopyContactText] = useState('');

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [annotationAttachmentIndex, setAnnotationAttachmentIndex] = useState(0);

  const [addActiveEmailModal, setAddActiveEmailModal] = useState(false);

  const [addEmailModalContact, setAddEmailModalContact] = useState(null);

  const [addEmailModalOpenedFrom, setAddEmailModalOpenedFrom] = useState('');

  const [addCCField, setAddCCField] = useState(false);

  const [toFieldError, setToFieldError] = useState('');

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const richTextEditorRef = useRef();
  const messageInput = useRef();
  const subjectInput = useRef();
  const hasSetTemplate = useRef(false);

  const uploadLexicalImagesToS3 = useUploadLexicalImagesToS3({ client });

  const { emailFeatureAvailable } = useIsEmailAvailable({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  const handlePostSendError = () => {
    dispatch(
      openSnackbar(
        i18n.t('settings-ProfileFormContainer-error', {
          defaultValue: 'There was an error!',
        }),
        'error'
      )
    );
  };

  const handlePostSendSuccess = () => {
    dispatch(
      openSnackbar(
        i18n.t('tasks-general-completedMessage', {
          defaultValue: 'Task completed successfully.',
        })
      )
    );
  };

  const { handlePostSendCompleteTask } = usePostSendCompleteTask({
    client,
    handlePostSendError,
    handlePostSendSuccess,
  });

  const onMutationStart = () => {
    dispatch(showUploadProgressBar());
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
  };

  const onMutationSuccess = (data) => {
    const startedEmailThread = data.startEmailThread.thread;
    let tab;
    senderGroup.allowInbound ? (tab = 'open') : (tab = 'closed');

    if (taskId) {
      const latestMessageId = startedEmailThread?.latestMessage?.id;

      handlePostSendCompleteTask({
        messageId: latestMessageId,
        taskId,
      });

      history.push('/tasks');
    } else {
      history.push(`/threads/${tab}/${startedEmailThread.id}`);
    }

    dispatch(hideUploadProgressBar());
  };

  const onMutationError = (err) => {
    setAttachments([]);
    dispatch(showUploadFailureBar());
    setTimeout(() => dispatch(hideUploadProgressBar()), 3000);
    console.error(err);
  };

  const handleNotifyError = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const {
    handleMutationStartThread,
    contact,
    setContact,
    contactEmail,
    setContactEmail,
    carbonCopyContacts,
    setCarbonCopyContacts,
    senderGroup,
    setSenderGroup,
    groupOptions,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    setMessageHTMLInputValue,
    messageSubjectValue,
    setMessageSubjectValue,
    attachments,
    setAttachments,
    deleteAttachment,
  } = useCreateEmail({
    client,
    currentUser,
    threadsActiveGroupIds,
    onMutationStart,
    onMutationSuccess,
    onMutationError,
    initialThread: createSection.createEmail,
    onError: handleNotifyError,
    activeEmailChannels,
  });

  useEffect(() => {
    const suggestion = createSection.createEmail.contact;
    if (contact?.emailAddress === null) {
      setContactEmail('');
      setContact(suggestion);
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('toField');
    }
  }, [createSection.createEmail.contact]);

  const { emailSigOn, autoAddSig, handleAddSignature } = useEmailSignature({
    client,
    userId: currentUser?.userId,
    messageVal: messageInputValue,
    setMessageVal: setMessageInputValue,
    richTextEditorRef,
    setHTMLVal: appendEmailSignature,
  });

  useEffect(() => {
    if (autoAddSig) handleAddSignature();
  }, [autoAddSig]);

  useEffect(() => {
    if (uploadModal.defaultContact) {
      const { defaultContact } = uploadModal;
      setContact(defaultContact);
      setContactEmail(defaultContact.emailAddress);
    }

    if (messageInput?.current) {
      autosize(messageInput.current);
    }

    return () => dispatch(setDefaultContact(null));
  }, []);

  useEffect(() => {
    if (messageInput?.current) {
      autosize.update(messageInput.current);
    }
  }, [messageInputValue]);

  const showFromField = threadsActiveGroupIds?.length > 1;

  const focusOnSubjectInput = () => subjectInput?.current?.focus?.();

  useEffect(() => {
    if (contactEmail) {
      focusOnSubjectInput();
    }
  }, [contactEmail]);

  const onRichTextEditorChange = (text) => {
    setMessageInputValue(text);
  };

  const [richTextInitiateSend, setRichTextInitiateSend] = useState(false);
  const onSendTrigger = async () => {
    if (ff_rich_text_email_formatting) {
      const uploadedImages = await uploadLexicalImagesToS3(
        richTextEditorRef.current,
        onFileError
      );

      // Stop send if any images failed to upload
      if (uploadedImages.some((image) => image.error)) return;

      const { html, text } = convertLexicalToHtmlAndText(
        richTextEditorRef.current
      );
      setMessageHTMLInputValue(html);
      setMessageInputValue(text);
      // Need to wait for state valus to update
      // before sending the mutation
      setRichTextInitiateSend(true);
    } else {
      handleMutationStartThread();
    }
  };

  useEffect(() => {
    if (richTextInitiateSend) {
      handleMutationStartThread();
      setRichTextInitiateSend(false);
    }
    // handleMutationStartThread is debounced, and thus
    // chanes its reference on each render; do not include in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [richTextInitiateSend]);

  const checkIsSuggestionAlreadyIncluded = (suggestion) =>
    carbonCopyContacts?.some(
      (carbonCopy) => carbonCopy?.emailAddress === suggestion
    );

  const checkIsSuggestionAlreadyMainContact = (suggestion) =>
    suggestion === contactEmail;

  const onSuggestionSelected = (e, { suggestion }) => {
    const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
      suggestion.emailAddress
    );
    if (suggestion.emailAddress) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
      } else {
        setContactEmail(suggestion.emailAddress);
        setContact(suggestion);
        setToFieldError('');
      }
    } else {
      setContactEmail('');
      setContact(suggestion);
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('toField');
    }
  };

  const onCCSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.emailAddress) {
      const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
        suggestion.emailAddress
      );
      const isMainContact = checkIsSuggestionAlreadyMainContact(
        suggestion.emailAddress
      );
      setCarbonCopyContactText('');
      if (isSuggestionAlreadyIncluded || isMainContact)
        dispatch(
          openSnackbar(
            i18n.t('slideouts-CreateThread-duplicateContact', {
              defaultValue: 'Duplicate contact entered',
            }),
            'error'
          )
        );
      if (
        !isSuggestionAlreadyIncluded &&
        !isMainContact &&
        carbonCopyContacts?.length <= 9
      ) {
        setCarbonCopyContacts([...carbonCopyContacts, suggestion]);
      }
    } else {
      setCarbonCopyContactText('');
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('cCField');
    }
  };

  const onCarbonCopyContactDeleted = (deletedContact) => {
    const newCarbonCopyContacts = carbonCopyContacts.filter(
      (contact) => contact.emailAddress !== deletedContact.emailAddress
    );
    setCarbonCopyContacts(newCarbonCopyContacts);
  };

  const onToFieldChange = (e) => setContactText(e.target.value);

  const onCCFieldChange = (e) => setCarbonCopyContactText(e.target.value);

  const handleInputChange = (e) => setMessageInputValue(e.target.value);

  const handleSubjectInputChange = (e) =>
    setMessageSubjectValue(e.target.value);

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isSuggestionAlreadyIncluded =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    if (emailRegex.test(trimmedValue) === true) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
      } else {
        setContactEmail(trimmedValue);
        setContactText(trimmedValue);
      }
    } else if (!contact || contactEmail === '') {
      setContactText('');
    }
  };

  const certifyCCInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isAlreadyMainContact =
      checkIsSuggestionAlreadyMainContact(trimmedValue);
    const isAlreadyInCarbonCopyContacts =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    setCarbonCopyContactText('');
    if (isAlreadyMainContact || isAlreadyInCarbonCopyContacts)
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateThread-duplicateContact', {
            defaultValue: 'Duplicate contact entered',
          }),
          'error'
        )
      );
    if (
      emailRegex.test(trimmedValue) === true &&
      !isAlreadyMainContact &&
      !isAlreadyInCarbonCopyContacts &&
      carbonCopyContacts.length <= 9
    ) {
      setCarbonCopyContacts([
        ...carbonCopyContacts,
        { emailAddress: trimmedValue },
      ]);
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      if (contactEmail) {
        handleMutationStartThread();
      }
    }
  };

  const handleSetAttachment = (
    originalFile,
    originalFilename = originalFile.name
  ) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachments((attachments) => [...attachments, attachment]);
    };
    reader.readAsDataURL(originalFile);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        setMessageInputValue(
          `${beforeSelection}${pastedText}${afterSelection}`
        );
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }

    const originalFile = clipBoardItem.getAsFile();
    handleSetAttachment(originalFile);
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  useEffect(() => {
    const createEmailRedux = {
      contact,
      contactEmail,
      messageInputValue,
      messageSubjectValue,
      attachments,
      senderGroup,
      carbonCopyContacts,
      taskId: createSection.createEmail.taskId,
      templateId: createSection.createEmail.templateId,
    };
    dispatch(setCreateEmail(createEmailRedux));
  }, [
    contact?.id,
    contactEmail,
    messageInputValue,
    attachments?.length,
    senderGroup?.value,
    taskId,
    templateId,
  ]);

  const clearToFieldBubble = () => {
    setContactText('');
    setContactEmail('');
    setContact(null);
  };

  const onFileError = (error) => {
    if (error instanceof I18nError) {
      dispatch(
        openSnackbar(i18n.t(error.i18n_id, error.i18n_variables), 'error')
      );
    } else {
      console.error(error);
    }
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    // Captures lexical drag to avoid opening dropzone
    if (e.dataTransfer.types.includes('text/lexical-image')) {
      return;
    }
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(
      acceptedFiles[0],
      onFileError,
      handleSetAttachment,
      attachments
    );
  };

  const handleEmojiSelection = (emoji) => {
    if (ff_rich_text_email_formatting) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: emoji.native,
        insertType: InsertType.insertAtSelection,
      });
    } else {
      const selectionStart = messageInput.current.selectionStart; // cursor group in input
      const beforeSelection = messageInput.current.value.slice(
        0,
        selectionStart
      );
      const afterSelection = messageInput.current.value.slice(selectionStart);
      setMessageInputValue(
        `${beforeSelection}${emoji.native} ${afterSelection}`
      );
      setTimeout(() => messageInput?.current?.focus?.(), 0);
    }

    setEmojiMenuOpen(false);
  };

  const handleTemplateApplication = (templateText) => {
    setMessageInputValue(templateText);
    if (ff_rich_text_email_formatting) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: templateText,
        insertType: InsertType.replaceNodes,
      });
    }
  };

  const handleTemplateAddAttachment = (attachment) => {
    setAttachments((attachments) => [...attachments, attachment]);
  };

  const handleOnModalError = () => {
    clearToFieldBubble();
    setAddEmailModalContact(null);
    setAddActiveEmailModal(false);
    setAddEmailModalOpenedFrom('');
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CreateEmail-errorAddEmail', {
          defaultValue: 'There was an error adding email address.',
        }),
        'error'
      )
    );
  };

  const renderAttachmentSection = () =>
    attachments.length ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={attachments}
          onClose={deleteAttachment}
          disableSendAsLink
          onAnnotate={(i) => {
            setAnnotationAttachmentIndex(i);
            setAnnotate(true);
          }}
          allowAnnotation
        />
      </FullFormRow>
    ) : null;

  const handleCloseAnnotation = (uri) => {
    setAnnotate(false);
    handleSaveAnnotation(uri);
  };

  const handleSaveAnnotation = (uri) => {
    const activeAttachment = attachments[annotationAttachmentIndex];
    const newAttachment = {
      data: uri,
      originalFilename: activeAttachment.originalFilename,
      type: activeAttachment.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: activeAttachment.size,
    };

    deleteAttachment(activeAttachment.id);
    setAttachments((attachments) => [...attachments, newAttachment]);
  };

  const inferAvatarChildren = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : undefined;
    }

    return undefined;
  };

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : contactEmail;
    }
    if (!contact && contactEmail) {
      return contactEmail;
    }
    return '';
  };

  const inferEntityCardSubText = (contact) => contact?.account?.name || '';

  const avatarUrl = resolveAvatarUrl(contact);

  const sharedValidation = !!(
    contactEmail && (showFromField ? senderGroup?.value : true)
  );

  const enableSubmissionWithMessage = sharedValidation && messageInputValue;
  const enableSubmissionWithAttachment = sharedValidation && attachments.length;

  const checkTemplateDisabled =
    !contactEmail ||
    !senderGroup ||
    contact?.__typename === 'User' ||
    contact?.__typename === 'Group';

  const handleTemplateButtonClick = () => {
    if (!checkTemplateDisabled) {
      setTemplateModalOpen(!templateModalOpen);
    }
  };

  const optionallyWrapWithDropzone = (children) =>
    ff_rich_text_email_formatting ? (
      children
    ) : (
      <Dropzone
        disableClick
        multiple={false}
        onDragEnter={handleOnDragEnter}
        onDragLeave={handleOnDragLeave}
        onDrop={handleOnDrop}
        style={modalBodyStyleWithDragging}
        data-testid="create-email-dropzone"
      >
        {children}
      </Dropzone>
    );

  const generateRTEContainerHeight = () => {
    if (!isExpanded) return 'initial';
    if (attachments?.length) return 'calc(100vh - 204px)';
    return 'calc(100vh - 116px)';
  };

  const generateRTEInputHeight = () => {
    if (!isExpanded) return 'initial';
    if (attachments?.length) return 'calc(100vh - 264px)';
    return 'calc(100vh - 175px)';
  };

  const { templateLoading, templateData } = useTemplateQuery({
    client,
    templateId,
  });

  useEffect(() => {
    if (
      taskId &&
      templateId &&
      templateData?.message &&
      !templateLoading &&
      !hasSetTemplate.current
    ) {
      const message = templateData.message
        .replaceAll('{FIRST_NAME}', contact?.firstName || ' ')
        .replaceAll('{LAST_NAME}', contact?.lastName || ' ')
        .replaceAll('{COMPANY_NAME}', contact?.account?.name || ' ')
        .replaceAll('{ACCOUNT_NAME}', accountName)
        .replaceAll('{GROUP_NAME}', senderGroup?.label?.split(',')[0] || ' ')
        .replaceAll('{AMOUNT_DUE}', ' ')
        .replaceAll('{INVOICE_NUMBER}', ' ');

      if (ff_rich_text_email_formatting) {
        appendEmailSignature(
          richTextEditorRef.current,
          message,
          InsertType.replaceNodes
        );
      } else {
        setMessageInputValue(message);
      }

      hasSetTemplate.current = true;
    }
  }, [
    accountName,
    contact?.account?.name,
    contact?.firstName,
    contact?.lastName,
    ff_rich_text_email_formatting,
    senderGroup,
    setMessageInputValue,
    taskId,
    templateData?.message,
    templateId,
    templateLoading,
  ]);

  return optionallyWrapWithDropzone(
    <>
      <Container data-testid="create-email-container">
        <InnerContainer centerVertically={isDraggingOnPage}>
          {isDraggingOnPage ? (
            <UploadArt height="60%" />
          ) : (
            <>
              <HeadingWrap>
                <Avatar size="lg" type="external" icon="emailChannel" />
                <Heading2 dataTestId="new-email-header">
                  {i18n.t('slideouts-CreateEmail-newEmail', {
                    defaultValue: 'New Email',
                  })}
                </Heading2>
              </HeadingWrap>
              {!emailFeatureAvailable && (
                <InputError
                  error={i18n.t('slideouts-CreateMessage-notEnabled', {
                    defaultValue: 'This channel is not enabled for this group',
                  })}
                />
              )}
              <Select
                key="createsection-createemail-senderGroup"
                label={i18n.t('slideouts-CreateThread-fromLabel')}
                options={groupOptions}
                value={senderGroup}
                onChange={(option) => setSenderGroup(option)}
                disabled={!emailFeatureAvailable || !showFromField}
                customOptionStyle={() => 'text-transform: none;'}
              />
              {contactEmail === '' && !contact?.id ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-toLabel'),
                    onBlur: certifyToInputValueOnBlur,
                    error: toFieldError,
                    value: contactText,
                    onChange: onToFieldChange,
                    autoFocus: true,
                    disabled: !emailFeatureAvailable,
                    placeholder: i18n.t('slideouts-CreateEmail-enterEmail', {
                      defaultValue: 'Enter email',
                    }),
                    rightSideLabelContent: !addCCField &&
                      emailFeatureAvailable && (
                        <Button
                          size="zero"
                          type="noStyle"
                          onClick={() => setAddCCField(true)}
                          customStyle={addCcCustomStyle}
                        >
                          {i18n.t('slideouts-CreateEmail-addCc')}
                        </Button>
                      ),
                  }}
                  query={SEARCH_CUSTOMERS_QUERY}
                  queryKey="searchCustomers"
                  queryVariables={{
                    query: contactText,
                    after: null,
                    typeFilter: 'CUSTOMER_CONTACT',
                    optedInEmail: true,
                  }}
                  onSelect={onSuggestionSelected}
                  includeGroupIndicator
                  isDisabled={(node) =>
                    node?.emailsOptOut || hasEmailBlocked(node)
                  }
                  rightAlignedText={(node) => {
                    if (hasEmailBlocked(node)) {
                      return i18n.t('threads-CreateThread-blocked', {
                        defaultValue: 'Blocked',
                      });
                    }
                    if (node?.emailsOptOut) {
                      return i18n.t(
                        'slideouts-GroupMessageRecipients-optedOut'
                      );
                    }
                    if (!node?.emailAddress) {
                      return i18n.t('slideouts-CreateEmail-addEmail', {
                        defaultValue: 'Add email',
                      });
                    }
                    return undefined;
                  }}
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  {!addCCField && (
                    <Button
                      size="zero"
                      type="noStyle"
                      onClick={() => setAddCCField(true)}
                      disabled={!emailFeatureAvailable}
                      customStyle={(props) =>
                        `position: absolute;
                        top: 8px;
                        right: 0;
                        ${addCcCustomStyle(props)}
                        `
                      }
                    >
                      {i18n.t('slideouts-CreateEmail-addCc')}
                    </Button>
                  )}
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateThread-toLabel')}
                    avatarChildren={inferAvatarChildren(contact)}
                    maintext={inferEntityCardMainText(contact)}
                    subtext={inferEntityCardSubText(contact)}
                    avatarProps={{
                      avatarUrl,
                      type: 'external',
                    }}
                    {...(!taskId ? { onDelete: clearToFieldBubble } : {})}
                  />
                </div>
              )}
              <>
                {addCCField && (
                  <InfiniteSuggest
                    inputProps={{
                      label: i18n.t('slideouts-CreateEmail-cC'),
                      onBlur: certifyCCInputValueOnBlur,
                      value: carbonCopyContactText,
                      onChange: onCCFieldChange,
                      disabled:
                        carbonCopyContacts?.length === 10 ||
                        !emailFeatureAvailable,
                      autoFocus: true,
                      placeholder: i18n.t('slideouts-CreateEmail-searchBy'),
                      hideBottomSpace: carbonCopyContacts?.length >= 1,
                      rightSideLabelContent: carbonCopyContacts?.length >=
                        1 && (
                        <Text
                          customStyle={(props) =>
                            `font-size: 12px; color: ${
                              carbonCopyContacts?.length === 10
                                ? THEMES.THEME_RED(props)
                                : THEMES.FOREGROUND_HIGH(props)
                            }`
                          }
                        >
                          {carbonCopyContacts?.length}
                          /10
                        </Text>
                      ),
                    }}
                    query={SEARCH_CUSTOMERS_QUERY}
                    queryKey="searchCustomers"
                    queryVariables={{
                      query: carbonCopyContactText,
                      after: null,
                      typeFilter: 'CUSTOMER_CONTACT',
                    }}
                    onSelect={onCCSuggestionSelected}
                    includeGroupIndicator
                    isDisabled={(node) =>
                      node?.emailsOptOut || hasEmailBlocked(node)
                    }
                    rightAlignedText={(node) => {
                      if (hasEmailBlocked(node)) {
                        return i18n.t('threads-CreateThread-blocked', {
                          defaultValue: 'Blocked',
                        });
                      }
                      if (node?.emailsOptOut) {
                        return i18n.t(
                          'slideouts-GroupMessageRecipients-optedOut'
                        );
                      }
                      if (!node?.emailAddress) {
                        return i18n.t('slideouts-CreateEmail-addEmail', {
                          defaultValue: 'Add email',
                        });
                      }
                      return undefined;
                    }}
                  />
                )}
                {carbonCopyContacts?.length >= 1 && (
                  <CarbonCopyChipsWrapper>
                    {carbonCopyContacts.map((contact, i) => {
                      const { emailAddress } = contact;
                      const name = contactName(contact);
                      return (
                        <ChipContainer key={i}>
                          <Chip
                            onDelete={() => onCarbonCopyContactDeleted(contact)}
                            customMaintextStyle={() =>
                              'max-width: 150px; text-overflow: ellipsis; overflow: hidden;'
                            }
                          >
                            {name || emailAddress}
                          </Chip>
                        </ChipContainer>
                      );
                    })}
                  </CarbonCopyChipsWrapper>
                )}
                <TextInput
                  ref={subjectInput}
                  value={messageSubjectValue}
                  label={i18n.t(
                    'slideouts-ForwardMessageSlideout-subjectLabel'
                  )}
                  onChange={handleSubjectInputChange}
                  placeholder={i18n.t('slideouts-CreateEmail-enterSub', {
                    defaultValue: 'Enter Subject',
                  })}
                  disabled={!emailFeatureAvailable}
                />
                <ExpandableWrapper isExpanded={isExpanded}>
                  {ff_rich_text_email_formatting ? (
                    <RichTextEditor
                      label={
                        isExpanded
                          ? null
                          : i18n.t('slideouts-CreateThread-message')
                      }
                      rightSideLabelContent={
                        isExpanded
                          ? null
                          : emailFeatureAvailable && (
                              <CreateMultiTag tags={tags} setTags={setTags} />
                            )
                      }
                      defaultText={i18n.t('slideouts-CreateThread-pressShift')}
                      onError={onFileError}
                      toolbarOptions={{
                        expandCollapse: {
                          expandable: true,
                          onExpand: () => setIsExpanded((prev) => !prev),
                        },
                      }}
                      onChangeListeners={[
                        ChangeFactory(
                          MutationMethod.text,
                          onRichTextEditorChange
                        ),
                      ]}
                      styleOverrides={{
                        containerHeight: generateRTEContainerHeight(),
                        minHeight: '50px',
                        maxHeight: isExpanded ? 'calc(100vh - 175px)' : '300px',
                        inputHeight: generateRTEInputHeight(),
                      }}
                      editorRef={richTextEditorRef}
                      testId="create-email-rich-text-editor"
                      attachments={attachments}
                    />
                  ) : (
                    <TextArea
                      label={i18n.t('slideouts-CreateThread-message')}
                      rightSideLabelContent={
                        emailFeatureAvailable && (
                          <CreateMultiTag tags={tags} setTags={setTags} />
                        )
                      }
                      placeholder={i18n.t('slideouts-CreateThread-pressShift')}
                      onKeyPress={handleEnterSubmit}
                      value={messageInputValue}
                      onChange={handleInputChange}
                      onPaste={handleOnPaste}
                      ref={messageInput}
                      id="textarea"
                      name="messageInputValue"
                      rows={8}
                      disabled={!emailFeatureAvailable}
                      dataTestId="at-textarea"
                      customLabelWrapperStyle={() => `
                    align-items: flex-end;
                    max-height: 38px;
                  `}
                      customTextAreaStyle={() => `
                    max-height: calc(100vh - 115px);
                  `}
                    />
                  )}
                  {renderAttachmentSection()}
                  <CreateSectionButtons
                    attachment={attachments}
                    enableSubmissionWithAttachment={
                      !!enableSubmissionWithAttachment
                    }
                    enableSubmissionWithMessage={!!enableSubmissionWithMessage}
                    handleMutationStartThread={onSendTrigger}
                    checkTemplateDisabled={checkTemplateDisabled}
                    hasSmsChannel={emailFeatureAvailable}
                    handleTemplateButtonClick={handleTemplateButtonClick}
                    handleFileReaderInputChange={(e) =>
                      handleFileReaderInputChange(
                        e,
                        onFileError,
                        handleSetAttachment,
                        attachments
                      )
                    }
                    handleEmojiSelection={handleEmojiSelection}
                    emojiMenuOpen={emojiMenuOpen}
                    setEmojiMenuOpen={setEmojiMenuOpen}
                    setMessageInputValue={handleTemplateApplication}
                    setAttachment={handleTemplateAddAttachment}
                    templateModalOpen={templateModalOpen}
                    setTemplateModalOpen={setTemplateModalOpen}
                    multiple
                    handleAddSignature={handleAddSignature}
                    emailSigOn={!!emailSigOn}
                    emailFeatureAvailable={emailFeatureAvailable}
                    messageInput={messageInput}
                    emailSignature
                    positionCursorOnSignatureAdd={
                      !ff_rich_text_email_formatting
                    }
                    contactId={contact?.id}
                    senderGroupId={senderGroup?.value}
                    channelType="EMAIL"
                  />
                </ExpandableWrapper>
              </>
            </>
          )}
        </InnerContainer>
        <AnnotationsModal
          open={annotate}
          setOpen={setAnnotate}
          attachment={attachments[annotationAttachmentIndex]}
          handleSave={handleCloseAnnotation}
        />
      </Container>
      {!!addEmailModalContact && (
        <AddEmailAddress
          open={addActiveEmailModal}
          onClose={() => {
            if (addEmailModalOpenedFrom === 'toField') {
              clearToFieldBubble();
            }
            setAddActiveEmailModal(false);
            setAddEmailModalContact(null);
          }}
          onSave={(contactEmail) => {
            setAddActiveEmailModal(false);
            if (addEmailModalOpenedFrom === 'toField') {
              setContactEmail(contactEmail);
            }
            if (addEmailModalOpenedFrom === 'cCField') {
              setCarbonCopyContacts([
                ...carbonCopyContacts,
                { ...addEmailModalContact, emailAddress: contactEmail },
              ]);
            }
            setAddEmailModalContact(null);
            dispatch(
              openSnackbar(
                i18n.t('slideouts-CreateEmail-emailAdded', {
                  contactName: contactName(addEmailModalContact),
                  defaultValue: `Email address for ${contactName(
                    addEmailModalContact
                  )} has been added.`,
                })
              )
            );
          }}
          onError={handleOnModalError}
          contact={addEmailModalContact}
        />
      )}
    </>
  );
};

CreateEmail.propTypes = {
  createSection: PropTypes.object.isRequired,
};

export default CreateEmail;
