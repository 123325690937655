import type { UiLabel } from 'client-lib/src/lib/utils/helpers/types';

import type { I18NType } from '../../../utils/helpers/types';
import { ContactNamedStatus } from './types';

export const getContactTypeOptions = (i18n: I18NType): UiLabel[] => [
  {
    label: i18n.t('contacts-filter-allContacts', {
      defaultValue: 'All contacts',
    }),
    value: ContactNamedStatus.All,
  },
  {
    label: i18n.t('contacts-filter-onlyNamedContacts', {
      defaultValue: 'Only Named contacts',
    }),
    value: ContactNamedStatus.Named,
  },
  {
    label: i18n.t('contacts-filter-onlyUnnamedContacts', {
      defaultValue: 'Only Unnamed Contacts',
    }),
    value: ContactNamedStatus.Unnamed,
  },
];

export const getContactTypeOption = (
  i18n: I18NType,
  selectOption: ContactNamedStatus
): UiLabel => {
  const contactTypeOptions = getContactTypeOptions(i18n);

  return (
    contactTypeOptions.find((option) => option.value === selectOption) ||
    contactTypeOptions[0]!
  );
};
