import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/textInput';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import InputError from '../inputCommonElements/InputError.tsx';
import Option from './shared/Option.tsx';
import DropdownIndicator from './shared/DropdownIndicator.tsx';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const Container = styled.div`
  width: 100%;
`;

const StyledSelect = styled(ReactSelect)`
  & .Select__control {
    border-radius: 4px;
    height: 40px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    font-weight: 500;
    font-family: 'Barlow', sans-serif;

    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

    background-color: ${(props) =>
      props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
    cursor: ${(props) => (props.disabled ? 'default' : 'auto')};

    ${(props) => props?.customInputStyle?.(props)}
  }

  & .Select__single-value {
    color: ${THEMES.TEXT};
  }

  & .Select__control:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};
  }

  & .Select__control--is-disabled {
    background-color: ${THEMES.DISABLED_BACKGROUND};
  }

  & .Select__control--is-focused {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  & .Select__control--is-focused:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__menu {
    margin-top: 1px;
  }

  & .Select__menu-list {
    padding: 0;
    border: 1px solid ${THEMES.BORDER};
    border-radius: 5px;
  }

  & .Select__menu {
    border-radius: 5px;
  }

  & .Select__option {
    height: 40px;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    color: ${THEMES.OPTION_TEXT};
    background-color: ${THEMES.OPTION_BACKGROUND};
    display: flex;
    align-items: center;
    padding: 0 8px;
    ${(props) => props?.customOptionStyle?.(props)};
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
  }

  & .Select__option:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  & .Select__option:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  & .Select__option--is-focused,
  .Select__option--is-focused:active {
    color: ${THEMES.OPTION_TEXT_HOVER};
    background-color: ${THEMES.OPTION_BACKGROUND_HOVER};
  }

  & .Select__option--is-selected {
    color: ${THEMES.OPTION_TEXT_ACTIVE};
    background-color: ${THEMES.OPTION_BACKGROUND_ACTIVE};
  }
  & .Select__indicators {
    display: ${(props) => (props.disabled ? 'none!important' : 'flex')};
  }
`;

const Select = React.forwardRef(
  (
    {
      label,
      options,
      onChange,
      placeholder,
      error,
      helperText,
      value,
      disabled,
      customContainerStyle,
      customLabelStyle,
      customInputStyle,
      customOptionStyle,
      customErrorContainerStyle,
      dataTestId,
      hideBottomSpace,
      isCheckable,
      isSearchable,
      ...otherProps
    },
    ref
  ) => {
    const matchedVal =
      typeof value === 'string' || typeof value === 'number'
        ? options.find((option) => option.value === value)
        : value;

    const createDropdownIndicator = (props) => (
      <DropdownIndicator dataTestId={dataTestId} {...props} />
    );

    const createOption = (props) => (
      <Option
        dataTestId={dataTestId}
        customOptionStyle={customOptionStyle}
        isCheckable={isCheckable}
        {...props}
      />
    );

    return (
      <Container
        error={error}
        disabled={disabled}
        customContainerStyle={customContainerStyle}
        css={(props) => props?.customContainerStyle?.(props)}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </InputLabel>
        ) : null}
        <StyledSelect
          options={options}
          value={matchedVal}
          onChange={onChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          classNamePrefix="Select"
          components={{
            DropdownIndicator: createDropdownIndicator,
            Option: createOption,
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          closeMenuOnSelect={!isCheckable}
          hideSelectedOptions={!isCheckable}
          isSearchable={isSearchable}
          data-testid={dataTestId}
          {...otherProps}
          ref={ref}
        />
        {!hideBottomSpace && (
          <InputError
            error={error}
            helperText={helperText}
            customErrorContainerStyle={customErrorContainerStyle}
            dataTestId={`input-error-${dataTestId}`}
          />
        )}
      </Container>
    );
  }
);

Select.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  customOptionStyle: PropTypes.func,
  customErrorContainerStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  hideBottomSpace: PropTypes.bool,
  isCheckable: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  placeholder: null,
  error: null,
  helperText: '',
  value: null,
  disabled: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  customOptionStyle: null,
  customErrorContainerStyle: null,
  dataTestId: 'select',
  hideBottomSpace: false,
  isCheckable: false,
  isSearchable: false,
};

Select.displayName = 'Select';

export default Select;
