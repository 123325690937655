import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLazyPaginated } from 'client-lib';
import i18n from 'i18n-js';
import { type GraphEdge, assertType } from '../../utils/helpers/types.ts';
import type {
  AsyncPaginateLoadOptionsFunction,
  OptionType,
} from '../../elements/Select/shared/types.ts';

export enum OptionTypeKeys {
  Label = 'label',
  Value = 'value',
  Color = 'color',
  AvatarContent = 'avatarContent',
  Role = 'role',
  ApplyLabelColor = 'applyLabelColor',
  RightSideOptionContent = 'rightSideOptionContent',
}
export interface IGenerateOptionMap {
  [key: string]: OptionTypeKeys;
}

interface IAsyncSelectPaginateHelper {
  generateOption:
    | (<T extends object>(node: T) => OptionType | null)
    | IGenerateOptionMap;
  query: string;
  key: string;
  queryVariables?: {
    [key: string]: string | number | boolean | string[] | undefined | null;
  };
  resultsNumber?: number;
}

/**
 * Custom hook to assist with asynchronous select pagination controls.
 * This helper is to prevent duplicate code when using the AsyncSelectPaginate control
 * around query paging handling.
 *
 * @param {IAsyncSelectPaginateHelper} params - The parameters for the hook.
 * @param {Function|IGenerateOptionMap} params.generateOption - Defintion of how to resolve the options returned from the query.  Either: option map for basic mapping, OR Function to generate a select option from a node.  Iterates over the GraphEdge shape and calls this function for each node.
 * @param {string} params.query - The GraphQL query string.
 * @param {string} params.key - The key to access the data in the query response.
 * @param {Object} [params.queryVariables] - Optional query variables.
 * @param {number} [params.resultsNumber] - Optional number of results to fetch per query.
 *
 * @returns {Object} The hook's return values.
 * @returns {Function} return.loadOptions - Function to load options based on input value and previous options.
 * @returns {string} return.queryError - Error message if the query fails.
 * @returns {Function} return.validOptions - Function to validate and filter options.
 */
const useAsyncSelectPaginateHelper = ({
  generateOption,
  query,
  key,
  queryVariables,
  resultsNumber,
}: IAsyncSelectPaginateHelper) => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState<string>('');

  /**
   * Converts a node to an option based on the generateOption map.
   * Should only be used when generateOption is an object.
   * @param node node to map to option
   * @returns OptionType deterived from node.  Any missing params are set to 'not_provided'
   */
  const mapEdgeNodeToOption = (node: {
    [key: string]: unknown;
  }): OptionType => {
    if (typeof generateOption !== 'object')
      throw new Error('generateOption must be an object');

    const optionMap = generateOption as unknown as IGenerateOptionMap;

    return Object.entries(optionMap).reduce(
      (acc, [edgeKey, optionKey]): OptionType => {
        if (typeof node[edgeKey] !== 'string') {
          // eslint-disable-next-line no-console
          console.warn('Node key is not a string:', node[edgeKey]);
          return acc;
        }
        return { ...acc, [optionKey]: node[edgeKey] };
      },
      { label: 'not_provided', value: 'not_provided' }
    );
  };

  const generateOptions = <T>(edges: GraphEdge<T>[]): OptionType[] => {
    const options = edges.map((edge: GraphEdge<T>) => {
      const { node } = edge;
      if (!node) return null;
      if (typeof generateOption === 'function') {
        return generateOption(node);
      }
      return mapEdgeNodeToOption(node);
    });
    return options.filter((option) => option !== null);
  };

  // @ts-expect-error - useLazyPaginated is not typed
  const { triggerQuery, handleFetchMore } = useLazyPaginated({
    client,
    query,
    key,
    resultsNumber,
    queryVariables,
  });

  const initialQuery = (inputVal: string) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: resultsNumber,
        ...queryVariables,
      },
    });
  };

  const fetchMore = (inputVal: string) => {
    return handleFetchMore({
      variables: {
        filter: inputVal,
      },
    });
  };

  /**
   * Load options using the query and existing queryVariables, which are set
   *  on load and updated as the user searches.
   * @param inputVal Search value used in the isSearchable text field
   * @param prevOptions Previous array of options displayed in the select box
   * @param additional Informational, current page, previous search value, etc
   * @returns
   */
  const loadOptions: AsyncPaginateLoadOptionsFunction = async (
    inputVal,
    prevOptions,
    additional?
  ) => {
    setQueryError('');
    let response;

    if (prevOptions?.length > 0 && additional?.prevInputVal === inputVal) {
      response = await fetchMore(inputVal);
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error } = response;

    const hasMore = data?.[key]?.pageInfo?.hasNextPage;
    const hasPreviousPage = data?.[key]?.pageInfo?.hasPreviousPage;

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setQueryError(
        `${i18n.t('slideouts-GroupMessageName-genericError')} ${error.toString()}`
      );
      return { options: [], hasMore };
    }

    const options = generateOptions(data?.[key]?.edges);

    return {
      options,
      hasMore,
      additional: {
        prevInputVal: inputVal,
        hasPreviousPage,
        hasNextPage: hasMore,
      },
    };
  };

  const validOptions = (options: (OptionType | null)[]): OptionType[] => {
    if (!Array.isArray(options)) return [];
    return options.filter((op) =>
      assertType<OptionType>(op, ['label', 'value'])
    );
  };

  return {
    loadOptions,
    queryError,
    validOptions,
  };
};

export default useAsyncSelectPaginateHelper;
