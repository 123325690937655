import styled from 'styled-components';
import React from 'react';
import THEMES from '../../../styles/themes/library/textInput';

const SelectDropdownArrow = styled.i<{ menuIsOpen: boolean }>`
  font-size: 32px;
  color: ${THEMES.TEXT};
  ${(props) => (props.menuIsOpen ? 'transform: scaleY(-1)' : '')};
`;

export interface DropdownIndicatorProps {
  selectProps: {
    menuIsOpen: boolean;
  };
  dataTestId?: string;
}

const DropdownIndicator = ({
  selectProps,
  dataTestId,
}: DropdownIndicatorProps) => (
  <SelectDropdownArrow
    className="ri-arrow-drop-down-line"
    menuIsOpen={selectProps?.menuIsOpen}
    data-testid={`${dataTestId}-dropdown-indicator`}
  />
);

export default DropdownIndicator;
