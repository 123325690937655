import React, { useState } from 'react';
import { WORK_ORDERS_QUERY } from 'client-lib';
import { isArray } from 'lodash';
import type { MultiValue } from 'react-select';
import AsyncSelectPaginate from '../../elements/Select/AsyncSelectPaginate/AsyncSelectPaginate.tsx';
import type {
  OptionType,
  MultiOnChange,
} from '../../elements/Select/shared/types.ts';
import { assertType } from '../../utils/helpers/types.ts';
import useAsyncSelectPaginateHelper, {
  OptionTypeKeys,
} from './useAsyncSelectPaginateHelper.ts';

interface TagsAsyncSelectPaginateProps {
  key?: string;
  value: OptionType[];
  setValue: (value: OptionType[]) => void;
  placeholder?: string;
  label: string;
  isMulti?: boolean;
  isClearable?: boolean;
  hideBottomSpace?: boolean;
  dataTestId?: string;
}

/**
 * TagsAsyncSelectPaginate component is a wrapper around AsyncSelectPaginate
 * that provides asynchronous pagination and selection of tags.
 */
const TagsAsyncSelectPaginate = ({
  key = 'tags-async-select',
  value,
  setValue,
  placeholder,
  label,
  isMulti = true,
  isClearable = true,
  dataTestId = 'tags-async-select',
  hideBottomSpace = true,
}: TagsAsyncSelectPaginateProps) => {
  const [searchValue, setSearchValue] = useState<string | null>(null);

  // const generateOption = <T extends object>(node: T): OptionType | null => {
  //   const tag = node;
  //   if (!assertType<WorkOrder>(tag, ['referenceNumber'])) return null;
  //   return {
  //     label: tag.referenceNumber,
  //     value: tag.id,
  //   };
  // };

  const generateOptionMap = {
    referenceNumber: OptionTypeKeys.Label,
    id: OptionTypeKeys.Value,
  };

  const variables = {
    referenceNumber: searchValue,
    orderBy: 'REFERENCE_NUMBER_ASC',
  };

  const { loadOptions, queryError, validOptions } =
    useAsyncSelectPaginateHelper({
      generateOption: generateOptionMap,
      query: WORK_ORDERS_QUERY,
      key: 'workOrders',
      queryVariables: {
        ...variables,
      },
      resultsNumber: 25,
    });

  const onChange: MultiOnChange = (selectedOptions) => {
    let newOptions: MultiValue<OptionType> = [];
    if (!isArray(selectedOptions)) {
      newOptions = [selectedOptions] as MultiValue<OptionType>;
    } else {
      newOptions = selectedOptions as MultiValue<OptionType>;
    }
    const selectOptions = newOptions.filter((option) =>
      assertType<OptionType>(option, ['label', 'value'])
    );

    setValue(selectOptions);
  };

  return (
    <AsyncSelectPaginate
      key={key}
      value={validOptions(value)}
      isMulti={isMulti}
      isClearable={isClearable}
      label={label}
      placeholder={placeholder}
      loadOptions={loadOptions}
      onInputChange={setSearchValue}
      onChange={onChange}
      dataTestId={dataTestId}
      error={queryError}
      hideBottomSpace={hideBottomSpace}
    />
  );
};

export default TagsAsyncSelectPaginate;
