import styled from 'styled-components';
import THEMES from '../../../styles/themes/library/textInput';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';

export const Container = styled.div<{
  error?: string | boolean;
  disabled?: boolean;
  prefix: string;
  customContainerStyle?: () => string;
}>`
  width: 100%;
  color: ${THEMES.TEXT};
  font-family: 'Barlow', sans-serif;
  .${({ prefix }) => prefix}__control {
    border-radius: 4px;
    min-height: 43px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    font-weight: 500;
    font-family: 'Barlow', sans-serif;

    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

    background-color: ${(props) =>
      props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
    cursor: ${(props) => (props.disabled ? 'default' : 'auto')};
  }

  .${({ prefix }) => prefix}__control:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};
  }

  .${({ prefix }) => prefix}__control--is-disabled {
    background-color: ${THEMES.DISABLED_BACKGROUND};
  }

  .${({ prefix }) => prefix}__control--is-focused {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  .${({ prefix }) => prefix}__control--is-focused:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  .${({ prefix }) => prefix}__indicator-separator {
    display: none;
  }

  .${({ prefix }) => prefix}__menu {
    margin-top: 1px;
    border-radius: 5px;
    background-color: ${THEMES.BACKGROUND};
    color: ${THEMES.TEXT};
  }

  .${({ prefix }) => prefix}__menu-list {
    padding: 0;
    border: 1px solid ${THEMES.BORDER};
    border-radius: 5px;
  }

  .${({ prefix }) => prefix}__option {
    height: 40px;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    color: ${THEMES.OPTION_TEXT};
    background-color: ${THEMES.OPTION_BACKGROUND};
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    border: 1px solid ${THEMES.BORDER};
  }

  .${({ prefix }) => prefix}__option:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  .${({ prefix }) => prefix}__option:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  .${({ prefix }) => prefix}__option--is-focused,
    .${({ prefix }) => prefix}__option--is-focused:active {
    color: ${THEMES.OPTION_TEXT_HOVER};
    background-color: ${THEMES.OPTION_BACKGROUND_HOVER};
  }

  .${({ prefix }) => prefix}__option--is-selected {
    color: ${THEMES.OPTION_TEXT_ACTIVE};
    background-color: ${THEMES.OPTION_BACKGROUND_ACTIVE};
  }

  .${({ prefix }) => prefix}__indicators {
    display: ${(props) => (props.disabled ? 'none !important' : 'flex')};
  }

  .${({ prefix }) => prefix}__value-container--has-value {
    gap: 8px;
    padding: 8px;
  }

  .${({ prefix }) => prefix}__input-container {
    color: ${THEMES.TEXT};
  }

  .${({ prefix }) => prefix}__single-value {
    color: ${THEMES.TEXT} !important;
  }

  ${(props) => props.customContainerStyle?.()}
`;

export const OptionWrapper = styled.div`
  display: flex;
`;

export const ChildrenWrapper = styled.div`
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OptionColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChildrenColumnWrapper = styled.div`
  flex-direction: column;
  text-overflow: ellipsis;
  justify-content: center;
  padding-left: 10px;
`;

export const RightSideOptionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 3;
`;
