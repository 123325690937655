import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LABEL_THEMES from '../../styles/themes/library/label';
import ChipLabel from '../../elements/Chip/ChipLabel';

const CONTACT_LABEL_COLORS = {
  red: LABEL_THEMES.RED_LABEL,
  orange: LABEL_THEMES.ORANGE_LABEL,
  green: LABEL_THEMES.GREEN_LABEL,
  blue: LABEL_THEMES.BLUE_LABEL,
  violet: LABEL_THEMES.PURPLE_LABEL,
  darkRed: LABEL_THEMES.DARK_RED_LABEL,
  darkGreen: LABEL_THEMES.DARK_GREEN_LABEL,
  darkGray: LABEL_THEMES.DARK_GRAY_LABEL,
};

const CONTACT_LABEL_TEXT = {
  red: LABEL_THEMES.LABEL_TEXT,
  orange: LABEL_THEMES.LABEL_TEXT,
  green: LABEL_THEMES.LABEL_TEXT,
  blue: LABEL_THEMES.LABEL_TEXT,
  violet: LABEL_THEMES.LABEL_TEXT,
  darkRed: LABEL_THEMES.LABEL_TEXT,
  darkGreen: LABEL_THEMES.LABEL_TEXT,
  darkGray: LABEL_THEMES.LABEL_TEXT,
};

const ChipLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  padding-top: 4px;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const ChipLabels = ({ options, handleOnDeleteLabel, size }) => {
  return (
    <ChipLabelContainer>
      {options?.value?.map((label, ind) => {
        return (
          <ChipLabel
            dataTestId={`chip-label-${ind}`}
            key={label.value}
            backgroundColor={
              label.color
                ? CONTACT_LABEL_COLORS[label.color]
                : LABEL_THEMES.GREY_LABEL
            }
            textColor={
              label.color
                ? CONTACT_LABEL_TEXT[label.color]
                : LABEL_THEMES.GREY_TEXT
            }
            size={size}
            onDelete={
              handleOnDeleteLabel
                ? () => {
                    handleOnDeleteLabel(label.value);
                  }
                : null
            }
            noOutline
          >
            {label.text || label.value}
          </ChipLabel>
        );
      })}
    </ChipLabelContainer>
  );
};

ChipLabels.propTypes = {
  handleOnDeleteLabel: PropTypes.func,
  options: PropTypes.object,
  size: PropTypes.string,
};

ChipLabels.defaultProps = {
  handleOnDeleteLabel: null,
  options: {},
  size: 'md',
};

export default ChipLabels;
