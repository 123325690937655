import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { USERS_QUERY, useLazyPaginated } from 'client-lib';
import { AsyncSelectPaginate } from '../../elements';

type Option = {
  value: string;
  label: string;
  avatarUrl?: string;
  role?: string;
};

type User = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  role: string;
};

type UserNode = {
  node: User;
};

type PaginateResponse = {
  edges: UserNode[];
};

type DataUsersResponseType = {
  users: PaginateResponse;
};

type AdditionalInput = {
  prevInputVal: string;
};

type QueryVars = {
  groupIds?: string[];
  includeAccountScopedUsers?: boolean;
  [key: string]: string | number | boolean | string[] | undefined;
};

interface UserAsyncSelectProps {
  value: string | Option;
  error?: string;
  queryVars: QueryVars;
  setValue: (selectedOption: Option) => void;
  onClear?: () => void;
  hideAvatar?: boolean;
  [key: string]: unknown; // This allows for any other props to be passed to the component for rest props
}

const UserAsyncSelect: React.FC<UserAsyncSelectProps> = ({
  value,
  setValue,
  error,
  queryVars,
  onClear,
  hideAvatar = false,
  ...props
}): JSX.Element => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState('');

  const { triggerQuery, handleFetchMore, pageInfo } = useLazyPaginated({
    client,
    query: USERS_QUERY,
    key: 'users',
    resultsNumber: 25,
    filter: value,
    queryVariables: {
      ...queryVars,
    },
  });

  const formatUsersToInputOptions = (data: DataUsersResponseType) => {
    return data?.users?.edges.map((user) =>
      hideAvatar
        ? {
            value: user.node.id,
            label: `${user.node.firstName} ${user.node.lastName}`,
            role: user.node.role,
          }
        : {
            value: user.node.id,
            role: user.node.role,
            label: `${user.node.firstName} ${user.node.lastName}`,
            avatarContent: user.node.avatarUrl,
          }
    );
  };

  const initialQuery = (inputVal: string) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: 25,
        ...queryVars,
      },
    });
  };

  const loadOptions = async (
    inputVal: string,
    prevOptions: User[],
    additional: AdditionalInput
  ) => {
    let response;
    if (prevOptions?.length > 0 && additional.prevInputVal === inputVal) {
      response = await handleFetchMore();
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error: responseError } = response;

    const hasMore = data?.users?.pageInfo?.hasNextPage;

    if (responseError) {
      setQueryError('error');
      return { options: [], hasMore: false };
    }

    const formattedUsers = formatUsersToInputOptions(data);

    return {
      options: formattedUsers,
      hasMore,
      additional: { prevInputVal: inputVal },
    };
  };

  return (
    <AsyncSelectPaginate
      value={value}
      key={`user-async-select-${queryVars?.groupIds?.[0] ?? ''}`}
      onChange={(selectedOption: Option) => {
        setValue(selectedOption);
      }}
      onClear={onClear}
      loadOptions={loadOptions}
      hasMore={pageInfo.hasNextPage}
      customOptionStyle={
        hideAvatar
          ? () => ''
          : () => 'padding: 8px; border-bottom: 1px solid #e0e0e0;'
      }
      error={queryError || error}
      dataTestId="user-async-select"
      {...props}
    />
  );
};

export default UserAsyncSelect;
