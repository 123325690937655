import { PK_TYPENAMES } from 'client-lib';
import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  TableHead,
  Button,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements';
import CommonTableRow from './subComponents/CommonTableRow';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  MediumHeader,
  SmallHeader,
} from '../sharedStyles.ts';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import {
  getSortOptions,
  getOptInStatusText,
  addSelectedDataTestId,
} from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';
import {
  SMS_STATUS,
  type CustomerContact,
  type PageInfo,
} from '../../../../utils/helpers/types';
import {
  ANNOUNCEMENT_TYPES,
  BROADCAST_LIST,
  BULK_TASK,
  type BROADCAST_AUDIENCE_BUILDER_TYPES,
} from '../../groupMessage/types';
import type { ContactsQueryFilters } from '../../../../elements/FilterBy/ContactsFilterBy.tsx';

interface ContactsTableProps {
  contacts: CustomerContact[];
  selectedAudience: {
    contactIds: string[];
    companyIds: string[];
    remove?: boolean;
  };
  loading: boolean;
  loadMoreRows: () => void;
  onAdd: (args: {
    contactIds: string[];
    companyIds: string[];
    remove?: boolean;
  }) => void;
  onFilter: (filters: ContactsQueryFilters) => void;
  onSort: (args: { label: string; value: string }) => void;
  pageInfo: PageInfo;
  sort: {
    label: string;
    value: string;
  };
  heightDifference?: number;
  disableCheckboxes: boolean;
  hasLabelsFF: boolean;
  type: BROADCAST_AUDIENCE_BUILDER_TYPES;
  noResultsCustomTranslation?: string;
  noResultsCustomTranslationDefault?: string;
}

const ContactsTable = ({
  contacts,
  selectedAudience = {
    contactIds: [],
    companyIds: [],
  },
  loading,
  loadMoreRows,
  onAdd,
  onFilter,
  onSort,
  pageInfo,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
  hasLabelsFF = false,
  type = BROADCAST_LIST.LIST,
  noResultsCustomTranslation = '',
  noResultsCustomTranslationDefault = '',
}: ContactsTableProps) => {
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [optedInContacts, setOptedInContacts] = useState<CustomerContact[]>([]);

  const sortOptions = getSortOptions(i18n);

  const {
    handleCheckItem,
    handleSelectNone,
    selectedItems,
    allItemsSelected,
    shouldCheckItem,
  } = useMultiSelectTableItems({
    items: optedInContacts,
  });

  const shouldItemBeChecked = (id: string) => {
    if (type === BULK_TASK.TASK) {
      return (
        selectedAudience?.contactIds?.includes(id) ||
        selectedAudience?.companyIds?.includes(id)
      );
    }
    return shouldCheckItem(id);
  };

  // Splits up selected ids by object type
  const handleCheckBtmList = (id: string, contactType: string) => {
    const newContacts: string[] = [...contactIds];
    const newCompanies: string[] = [...companyIds];

    if (contactType === PK_TYPENAMES.CUSTOMER_CONTACT) {
      if (shouldCheckItem(id)) {
        newContacts.splice(
          newContacts?.findIndex((contact) => contact === id),
          1
        );
      } else {
        newContacts.push(id);
      }
    } else if (shouldCheckItem(id)) {
      newCompanies.splice(
        newCompanies?.findIndex((comp) => comp === id),
        1
      );
    } else {
      newCompanies.push(id);
    }
    handleCheckItem(id);
    setContactIds(newContacts);
    setCompanyIds(newCompanies);
  };

  // Handles updating selected audience from parent
  const handleCheckBulkTask = (id: string, contactType: string) => {
    handleCheckItem(id);

    if (contactType === PK_TYPENAMES.CUSTOMER_CONTACT) {
      const isSelected = selectedAudience.contactIds.includes(id);
      onAdd({
        contactIds: [id],
        companyIds: [],
        remove: isSelected,
      });
    } else {
      const isSelected = selectedAudience.companyIds.includes(id);
      onAdd({
        contactIds: [],
        companyIds: [id],
        remove: isSelected,
      });
    }
  };

  useEffect(() => {
    if (allItemsSelected) {
      const newContacts: string[] = [];
      const newCompanies: string[] = [];
      optedInContacts.forEach((item) => {
        if (item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
          newContacts.push(item.id);
        } else newCompanies.push(item.id);
      });
      setContactIds(newContacts);
      setCompanyIds(newCompanies);
    }
  }, [allItemsSelected, optedInContacts]);

  useEffect(() => {
    if (type !== BROADCAST_LIST.LIST) {
      setOptedInContacts(
        contacts.filter(
          (c) =>
            (type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
              !c.announcementsOptOut) ||
            (type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
              !c.announcementsOptOut &&
              !c.promotionalBroadcastOptOut) ||
            c.__typename !== PK_TYPENAMES.CUSTOMER_CONTACT
        )
      );
    } else {
      setOptedInContacts(contacts);
    }
  }, [contacts, type]);

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ companyIds, contactIds });
    setCompanyIds([]);
    setContactIds([]);
    handleSelectNone();
  };

  /**
   * Check if a given contact should be allowed to be added to the list
   * @returns true if contact is able to be added to list
   */
  const checkContactDisabled = (contact: CustomerContact): boolean => {
    const badStatuses = [SMS_STATUS.landline, SMS_STATUS.not_in_service];

    if (disableCheckboxes) return true;
    if (
      contact.smsStatus &&
      badStatuses.some((status) => contact.smsStatus.includes(status))
    )
      return true;
    if (type === BULK_TASK.TASK && contact.conversationsOptOut) return true;
    if (
      type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
      contact.announcementsOptOut
    )
      return true;
    if (contact.announcementsOptOut === null && type !== BULK_TASK.TASK)
      return true;
    if (
      type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
      (contact.promotionalBroadcastOptOut || contact.announcementsOptOut)
    )
      return true;
    return false;
  };

  return (
    <>
      <FlexBetweenContainer>
        <AudienceFilterSort
          hasFilter
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitFilter={onFilter}
          handleSubmitSort={onSort}
        />
        {type !== BULK_TASK.TASK ? (
          <Button
            dataTestId={`contact-table-${addSelectedDataTestId}`}
            onClick={() => handleAdd()}
            disabled={selectedItems.length === 0 || disableCheckboxes}
          >
            {i18n.t('broadcasts-broadcast-addSelected', {
              defaultValue: 'Add %{number} Selected',
              number: selectedItems.length,
            })}
          </Button>
        ) : null}
      </FlexBetweenContainer>
      <Table data-testid="audiencebuildertables-contacts-table">
        <TableHead>
          <tr>
            <MediumHeader>
              {i18n.t('settings-FinalMessage-customer', {
                defaultValue: 'contact',
              })}
            </MediumHeader>
            <SmallHeader>
              {i18n.t('settings-Groups-groups', { defaultValue: 'groups' })}
            </SmallHeader>
            <SmallHeader>
              {hasLabelsFF
                ? i18n.t('settings-Label-labels', { defaultValue: 'labels' })
                : ''}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('broadcasts-broadcast-optin', {
                defaultValue: 'Opt-in Status',
              })}
            </SmallHeader>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={contacts}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {noResultsCustomTranslation
                ? i18n.t(noResultsCustomTranslation, {
                    defaultValue:
                      noResultsCustomTranslationDefault || 'No results found.',
                  })
                : i18n.t('slideouts-TargetedAudience-noResults', {
                    defaultValue:
                      'No results were found for this type of audience.',
                  })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: { list: CustomerContact[]; index: number }) => {
          const { list, index } = args;
          const contact = list[index] as CustomerContact;
          return (
            <CommonTableRow
              key={contact.id}
              id={contact.id}
              checked={contact.id ? shouldItemBeChecked(contact.id) : false}
              name={
                contact.__typename === PK_TYPENAMES.CUSTOMER_ACCOUNT
                  ? contact.name
                  : contactName(contact)
              }
              onCheck={
                type === BULK_TASK.TASK
                  ? () => handleCheckBulkTask(contact.id, contact.__typename)
                  : () => handleCheckBtmList(contact.id, contact.__typename)
              }
              type={contact.__typename}
              company={contact.account?.name}
              groupIds={contact.groupIds}
              labels={contact.labels}
              optInStatusText={getOptInStatusText(
                contact,
                i18n,
                type === BULK_TASK.TASK
              )}
              disabled={checkContactDisabled(contact)}
              hasLabelsFF={hasLabelsFF}
            />
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </>
  );
};

export default ContactsTable;
