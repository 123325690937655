import i18n from 'i18n-js';
import React from 'react';
import { Modal } from '../../../../../elements/index.ts';

const cardStyles = () => `
  div:nth-child(2) {
    padding-top: 0;
    div {
      align-items: flex-start;
    }
  }
`;

interface ExitConfirmationModalProps {
  dataTestId: string;
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
}

const ExitConfirmationModal = ({
  dataTestId,
  isOpen,
  onSave,
  onCancel,
}: ExitConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      modalTitle={i18n.t('broadcasts-audienceBuilder-areYouSure')}
      primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm')}
      primaryButtonOnClick={onSave}
      secondaryButtonText={i18n.t('settings-AddOrEditList-cancel')}
      secondaryButtonOnClick={onCancel}
      dataTestId={`${dataTestId}-audience-builder-exit-confirmation`}
      customCardProps={{ customStyle: cardStyles }}
      size="sm"
    >
      {i18n.t('broadcasts-audienceBuilder-exitConfirmation')}
    </Modal>
  );
};

export default ExitConfirmationModal;
