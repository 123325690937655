/**
 * Types of multi-select filters.
 *
 * @readonly
 * @enum {string}
 */
export const MULTI_SELECT_FILTER = {
  ALL: 'all',
  NONE: 'none',
  PAGE: 'page',
} as const;
